import React from "react";
import ReactDOM from "react-dom";

import "material-icons/iconfont/material-icons.css";
import "./index.scss";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";

import App from "routes/app";
import Items from "routes/items";
import Board from "routes/board";
import Item from "routes/item";
import Accounts from "routes/accounts";

import Token from "routes/app/auth/token";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/accounts/" element={<Accounts />} />
            <Route path="/items/" element={<Items />} />
            <Route path="/board/:boardId/" element={<Board />}>
              <Route
                path="/board/:boardId/item/:itemId/"
                element={<Item useDialog={true} />}
              />
            </Route>
            <Route path="/item/:itemId/" element={<Item useDialog={false} />} />
          </Route>
        </Routes>
      </Router>
    </PersistGate>
    <Token />
  </Provider>,
  document.getElementById("natodo")
);
