import {
  ATTEMPT_TO_LOGIN,
  ASSIGN_LOGGED_IN_USER,
  UNASSIGN_LOGGED_IN_USER,
  LOAD_USER,
} from "./actions";

const initialData = {
  id: null,
  name: null,
  picture: null,
  isLoading: true,
  isAdmin: false,
};

export function user(state = initialData, action) {
  switch (action.type) {
    case ATTEMPT_TO_LOGIN:
      return { ...state, isLoading: true };
    case ASSIGN_LOGGED_IN_USER:
    case LOAD_USER:
      return { ...state, ...action.user, isLoading: false };
    case UNASSIGN_LOGGED_IN_USER:
      return { ...initialData };
    default:
      return state;
  }
}

export default user;
