import { GET_BOARDS, LOAD_BOARDS } from "./actions";
import { getById } from "utils/array";

import { LOAD_ITEMS } from "../items/actions";

const initialData = {
  byId: {},
  all: [],
  isLoading: true,
};

export function boards(state = initialData, action) {
  switch (action.type) {
    case GET_BOARDS:
      return { ...state, isLoading: true };
    case LOAD_BOARDS: {
      const boards = [...action.boards].map((board) => ({
        ...board,
        items: {
          byId: {},
          all: [],
        },
      }));

      return {
        ...state,
        byId: getById(boards),
        all: boards,
        isLoading: false,
      };
    }

    case LOAD_ITEMS: {
      if (!state.byId[action.boardId]) {
        return state;
      }

      const boards = [...state.all].map((board) => {
        if (board.id !== action.boardId) {
          return board;
        }

        const items = [...action.items]
          .reduce(
            (agg, item) =>
              item.boardId === action.boardId ? [...agg, item] : agg,
            []
          )
          .sort((a, b) => a.indexAlt - b.indexAlt);

        return {
          ...board,
          items: {
            byId: getById(items),
            all: items,
          },
        };
      });

      return {
        ...state,
        byId: getById(boards),
        all: boards,
      };
    }

    default:
      return state;
  }
}

export default boards;
