import { Component } from "react";

import TextEditor from "components/form/text-editor";
import TextEditorOutput from "components/form/text-editor/output";

import Button from "components/button";

import UserPicture from "components/user/picture";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

export default class Comment extends Component {
  state = {
    isEditing: false,
    isDeleting: false,
  };

  get currentUser() {
    return this.props.currentUser;
  }

  get isCurrentUser() {
    return this.currentUser?.id === this.user?.id;
  }

  get user() {
    return this.props.user;
  }

  get model() {
    return this.props.model;
  }

  get date() {
    return new Date(this.model.timestamp);
  }

  get isAdmin() {
    return this.currentUser.isAdmin;
  }

  onEdit() {
    this.setState({ isEditing: true });
  }

  onDelete() {
    this.setState({ isDeleting: true });
  }

  async onSave() {
    await this.props.onUpdate(this.state.comment);
    await this.setState({ isEditing: false });
  }

  onCancel() {
    this.setState({ isEditing: false, isDeleting: false });
  }

  onChange(column, value) {
    this.setState({
      [column]: value,
    });
  }

  onDeleteConfirm() {
    return this.props.onDelete();
  }

  render() {
    const { model, user, currentUser, isCurrentUser, isAdmin } = this;
    const { isEditing, isDeleting } = this.state;

    return (
      <div className="comment">
        <div className="header">
          {user ? (
            <div className="user">
              <UserPicture userId={user.id} />
              <div className="name">{user?.name}</div>
            </div>
          ) : null}
          <div className="date" title={model.timestamp}>
            {timeAgo.format(this.date)}
          </div>
        </div>
        <div className="body">
          {isEditing ? (
            <TextEditor
              onChange={(comment) => this.onChange("comment", comment)}
              data={model.value}
              stayFocused={true}
            />
          ) : (
            <TextEditorOutput
              data={model.value}
              uniqueId={`comment-${model.id}`}
            />
          )}
        </div>

        {isCurrentUser || isAdmin ? (
          isEditing ? (
            <div className="footer">
              <Button onClick={() => this.onSave()} type="text-inline">
                Save
              </Button>
              <Button onClick={() => this.onCancel()} type="text-inline">
                Cancel
              </Button>
            </div>
          ) : isDeleting ? (
            <div className="footer">
              <div className="confirm">
                <span>Are you sure you want to delete?</span>
                <Button onClick={() => this.onCancel()} type="text-inline">
                  No
                </Button>
                <Button
                  onClick={() => this.onDeleteConfirm()}
                  type="text-inline"
                >
                  Yes
                </Button>
              </div>
            </div>
          ) : (
            <div className="footer">
              <Button onClick={() => this.onEdit()} type="text-inline">
                Edit
              </Button>
              <Button onClick={() => this.onDelete()} type="text-inline">
                Delete
              </Button>
            </div>
          )
        ) : null}
      </div>
    );
  }
}
