import TextEditor from "components/form/text-editor";
import { Component } from "react";

import Button from "components/button";

import Comment from "./comment";

export default class Comments extends Component {
  state = {
    comment: null,
  };

  onChange(column, value) {
    this.setState({
      [column]: value,
    });
  }

  async onUpdate(comment) {
    await this.props.onCommentUpdate(comment);
  }

  async onDelete(comment) {
    await this.props.onCommentDelete(comment);
  }

  async onComment() {
    this.setState({
      isSubmitting: true,
    });

    await this.props.onComment(this.state.comment);

    this.setState({
      isSubmitting: false,
      comment: null,
    });
  }

  render() {
    const { comments, users, currentUser } = this.props;

    return (
      <div className="comments">
        <div className="add">
          <TextEditor
            name="add_comment"
            onChange={(comment) => this.onChange("comment", comment)}
            data={this.state.comment}
          />
          <Button onClick={() => this.onComment()}>Comment</Button>
        </div>
        {comments.all.map((comment) => (
          <Comment
            model={comment}
            user={users.byId[comment.userId]}
            currentUser={currentUser}
            key={`comment-${comment.id}`}
            onUpdate={(value) => this.onUpdate({ ...comment, value })}
            onDelete={(comment) => this.onDelete(comment)}
          />
        ))}
      </div>
    );
  }
}
