import { LOAD_HISTORY, GET_HISTORY } from "./actions";
import { getById } from "utils/array";

const initialData = {
  byId: {},
  all: [],
  isLoading: true,
};

export function history(state = initialData, action) {
  switch (action.type) {
    case GET_HISTORY:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_HISTORY: {
      return {
        ...state,
        byId: getById(action.history),
        all: action.history,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

export default history;
