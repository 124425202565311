import { Component } from "react";

export default class extends Component {
  render() {
    const { token, children } = this.props;
    window["auth_token"] = token;

    if (token) {
      this.props.getUser();
    }

    console.log("token", token);

    return null;
  }
}
