export const API_URL = `//${process.env.REACT_APP_SERVER_DOMAIN}`;
export const getAxiosOptions = (useAuthToken = true, options = {}) => {
  if (!useAuthToken) {
    return {
      ...options,
    };
  }

  return {
    ...options,
    headers: {
      ...options.headers,
      token: window["auth_token"],
    },
  };
};

export const onError = (error) => {
  if (error.response.status === 401) {
    window["auth_token"] = undefined;
    localStorage.removeItem("auth_token");
  }

  return null;
};
