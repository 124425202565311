import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

const getItemChecklists = (itemId) =>
  axios
    .get(`${API_URL}/checklists.get/items/${itemId}`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export const add = (type, associatedId, checklist) =>
  axios
    .post(
      `${API_URL}/checklists.post/${type}/${associatedId}`,
      { checklist },
      getAxiosOptions()
    )
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export const checklistOnItem = (itemId, checklist) =>
  add("items", itemId, checklist);

export const remove = (checklist) =>
  axios
    .delete(`${API_URL}/checklists.remove/${checklist.id}/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const update = (items) =>
  axios
    .post(`${API_URL}/checklists.update`, { items }, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export default { add, remove, update, checklistOnItem, getItemChecklists };
