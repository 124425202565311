import API_COMMENTS from "api/comments";

export const LOAD_COMMENTS = "VIEW_ITEM_LOAD_COMMENTS";

export const GET_COMMENTS = "VIEW_ITEM_GET_COMMENTS";
export const GET_COMMENTS_SUCCESS = "VIEW_ITEM_GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_FAILED = "VIEW_ITEM_GET_COMMENTS_FAILED";

export const UPDATE_COMMENTSS = "VIEW_ITEM_UPDATE_COMMENTSS";
export const UPDATE_COMMENTSS_FAILED = "VIEW_ITEM_UPDATE_COMMENTSS_FAILED";
export const UPDATE_COMMENTSS_SUCCESS = "VIEW_ITEM_UPDATE_COMMENTSS_SUCCESS";

export const UPDATE_COMMENTS = "VIEW_ITEM_UPDATE_COMMENTS";
export const UPDATE_COMMENTS_FAILED = "VIEW_ITEM_UPDATE_COMMENTS_FAILED";
export const UPDATE_COMMENTS_SUCCESS = "VIEW_ITEM_UPDATE_COMMENTS_SUCCESS";

export const ADD_ITEM_COMMENT = "VIEW_ITEM_ADD_ITEM_COMMENT";
export const ADD_ITEM_COMMENT_FAILED = "VIEW_ITEM_ADD_ITEM_COMMENT_FAILED";
export const ADD_ITEM_COMMENT_SUCCESS = "VIEW_ITEM_ADD_ITEM_COMMENT_SUCCESS";

export const UPDATE_ITEM_COMMENT = "VIEW_ITEM_UPDATE_ITEM_COMMENT";
export const UPDATE_ITEM_COMMENT_FAILED =
  "VIEW_ITEM_UPDATE_ITEM_COMMENT_FAILED";
export const UPDATE_ITEM_COMMENT_SUCCESS =
  "VIEW_ITEM_UPDATE_ITEM_COMMENT_SUCCESS";

export const DELETE_ITEM_COMMENT = "VIEW_ITEM_DELETE_ITEM_COMMENT";
export const DELETE_ITEM_COMMENT_FAILED =
  "VIEW_ITEM_DELETE_ITEM_COMMENT_FAILED";
export const DELETE_ITEM_COMMENT_SUCCESS =
  "VIEW_ITEM_DELETE_ITEM_COMMENT_SUCCESS";

const getItemId = (getState) => getState().views.item.item.id;

export const loadComments = (comments) => {
  return {
    type: LOAD_COMMENTS,
    comments,
  };
};

export const getComments = () => async (dispatch, getState) => {
  const itemId = getItemId(getState);

  dispatch({
    type: GET_COMMENTS,
    itemId,
  });

  const comments = await API_COMMENTS.get("items", itemId);

  if (!comments) {
    return dispatch({
      type: GET_COMMENTS_FAILED,
    });
  }

  dispatch({
    type: GET_COMMENTS_SUCCESS,
  });

  dispatch(loadComments(comments));
};

export const updateComments = (comments) => async (dispatch) => {
  dispatch({
    type: UPDATE_COMMENTS,
    comments,
  });

  const result = await API_COMMENTS.update(comments);

  if (!result) {
    return dispatch({
      type: UPDATE_COMMENTS_FAILED,
    });
  }

  dispatch({
    type: UPDATE_COMMENTS_SUCCESS,
  });

  dispatch(getComments());
};

export const addComment = (comment) => async (dispatch, getState) => {
  const itemId = getItemId(getState);

  dispatch({
    type: ADD_ITEM_COMMENT,
    comment,
  });

  const result = await API_COMMENTS.commentOnItem(itemId, comment);

  if (!result) {
    return dispatch({
      type: ADD_ITEM_COMMENT_FAILED,
    });
  }

  dispatch({
    type: ADD_ITEM_COMMENT_SUCCESS,
  });

  dispatch(getComments());
};

export const updateComment = (comment) => async (dispatch) => {
  dispatch({
    type: UPDATE_ITEM_COMMENT,
    comment,
  });

  const result = await API_COMMENTS.update(comment);

  if (!result) {
    return dispatch({
      type: UPDATE_ITEM_COMMENT_FAILED,
    });
  }

  dispatch({
    type: UPDATE_ITEM_COMMENT_SUCCESS,
  });

  dispatch(getComments());
};

export const deleteComment = (comment) => async (dispatch) => {
  dispatch({
    type: DELETE_ITEM_COMMENT,
    comment,
  });

  const result = await API_COMMENTS.remove(comment);

  if (!result) {
    return dispatch({
      type: DELETE_ITEM_COMMENT_FAILED,
    });
  }

  dispatch({
    type: DELETE_ITEM_COMMENT_SUCCESS,
  });

  dispatch(getComments());
};
