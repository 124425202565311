import React, { Component } from "react";
import { Node } from "slate";

const serialize = (value) => {
  return (
    value
      // Return the string content of each paragraph in the value's children.
      .map((n) => Node.string(n))
      // Join them all with line breaks denoting paragraphs.
      .join("\n")
  );
};

export default class TextEditorOutputPlain extends Component {
  get data() {
    return this.props.data;
  }

  get maxLength() {
    return this.props.maxLength ?? this.outputLength;
  }

  get outputLength() {
    return this.output?.length;
  }

  get output() {
    try {
      const json = JSON.parse(this.data);

      if (!(json?.length > 0)) {
        return null;
      }

      return serialize(json);
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <span className="text-editor-output">
        {this.output?.substr(0, this.maxLength)}
        {this.maxLength < this.outputLength ? "..." : ""}
      </span>
    );
  }
}
