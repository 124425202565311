import { connect } from "react-redux";
import Picture from "./picture";

function mapStateToProps(state) {
  return {
    users: state.users.byId,
  };
}

export default connect(mapStateToProps)(Picture);
