import { Component } from "react";
import Fieldset from "components/form/fieldset";
import TextInput from "components/form/text-input";

import Button from "components/button";

import "./login.scss";

export default class extends Component {
  state = {
    email: null,
    password: null,
  };

  onInput(column, value) {
    this.setState({
      [column]: value,
    });
  }

  async onLogin() {
    const { email, password } = this.state;
    await this.props.attemptToLogin(email, password);
  }

  render() {
    return (
      <div className="login">
        <Fieldset title="Login" text="Enter your details below">
          <TextInput
            label="Email address"
            type="email"
            name="email"
            onUpdate={(value) => this.onInput("email", value)}
          />
          <TextInput
            label="Password"
            type="password"
            name="password"
            onUpdate={(value) => this.onInput("password", value)}
          />
        </Fieldset>
        <Button onClick={() => this.onLogin()}>Login</Button>
      </div>
    );
  }
}
