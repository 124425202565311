import { connect } from "react-redux";
import Profile from "./profile.js";

import { updateUser } from "store/user/actions";

function Wrapper(props) {
  return <Profile {...props} />;
}

function mapStateToProps(state) {
  return { user: state.user };
}

const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
