import { Component } from "react";
import Icon from "components/icon";

import "./button.scss";

export default class extends Component {
  get classNames() {
    const classes = ["button"];

    if (this.isActive) {
      classes.push("is-active");
    }

    if (this.type === "text-inline") {
      classes.push("text-inline");
    }
    if (this.icon) {
      classes.push("icon");
    }

    return classes.join(" ");
  }

  get isActive() {
    return this.props.isActive ?? false;
  }

  get type() {
    return this.props.type ?? "default";
  }

  get icon() {
    return this.props.icon;
  }

  get disabled() {
    return !(this.props?.isEnabled ?? true);
  }

  onClick(e) {
    this.props.onClick?.(e);

    return (
      this.props.onClick ??
      console.warn("Component button is not linked up", { ...this })
    );
  }

  render() {
    if (this.icon) {
      return (
        <button
          className={this.classNames}
          onClick={(e) => this.onClick(e)}
          disabled={this.disabled}
        >
          <Icon icon={this.icon} />
        </button>
      );
    }

    return (
      <button
        className={this.classNames}
        onClick={(e) => this.onClick(e)}
        disabled={this.disabled}
      >
        {this.props.children}
      </button>
    );
  }
}
