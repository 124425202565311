import TextEditorOutputPlain from "components/form/text-editor/output-plain";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

const priorities = ["Low Priority", "Medium Priority", "High Priority"];

export default function Item(props) {
  const { users, lists, boards } = props;

  let model = { ...props.model };

  const user = users.byId[model.userId];
  let action = "updated";

  if (model.column === "description") {
    // Todo: Parse text better/reduce etc
    model.before = (
      <TextEditorOutputPlain data={model.before} maxLength={200} />
    );
    model.after = <TextEditorOutputPlain data={model.after} maxLength={200} />;
  }

  if (model.column === "assigneeId") {
    model.column = "assignee";
    model.before = users.byId[model.before]?.name ?? "No one";
    model.after = users.byId[model.after]?.name ?? "No one";
  }

  if (model.column === "priority") {
    model.before = priorities[model.before];
    model.after = priorities[model.after];
  }

  if (model.column === "listId") {
    model.column = "list";
    model.before = lists.byId[model.before]?.name;
    model.after = lists.byId[model.after]?.name;
  }

  if (model.column === "boardId") {
    model.column = "board";
    model.before = boards.byId[model.before]?.name;
    model.after = boards.byId[model.after]?.name;
  }

  if (model.column === "index") {
    return (
      <div className="item" title={`From ${model.before} to ${model.after}`}>
        {user.name} {action} order
        <div className="date" title={model.timestamp}>
          {timeAgo.format(new Date(model.timestamp ?? 0))}
        </div>
      </div>
    );
  }

  return (
    <div className="item">
      {user.name} {action} {model.column} from "{model.before}" to "
      {model.after}"
      <div className="date" title={model.timestamp}>
        {timeAgo.format(new Date(model.timestamp ?? 0))}
      </div>
    </div>
  );
}
