import { Component } from "react";
import { render } from "react-dom";

import Dialog from "components/dialog";
import Button from "components/button";

let resolve;

class Confirm extends Component {
  constructor() {
    super(...arguments);

    this.state = {
      isOpen: false,
      props: {},
    };

    this.confirm = this.confirm.bind(this);
  }

  static create(props = {}) {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return render(<Confirm props={props} />, containerElement);
  }

  onCancel() {
    this.setState({ isOpen: false });
    resolve(false);
  }

  onConfirm() {
    this.setState({ isOpen: false });
    resolve(true);
  }

  confirm(props = {}) {
    const showConfirmProps = { ...this.props.props, ...props };
    this.setState({ isOpen: true, props: showConfirmProps });
    return new Promise((res) => {
      resolve = res;
    });
  }

  render() {
    const { isOpen } = this.state;

    return isOpen ? (
      <Dialog title="Confirm action" size="small" hasClose={false}>
        <div className="dialog-confirm">
          <h1>Are you sure?</h1>
          {this.state.props.message ? (
            <p>{this.state.props.message}</p>
          ) : (
            <p>The action you are about to perform cannot be undone.</p>
          )}
          <div className="buttons">
            <Button onClick={() => this.onConfirm()}>Confirm</Button>
            <Button onClick={() => this.onCancel()}>Cancel</Button>
          </div>
        </div>
      </Dialog>
    ) : null;
  }
}

export default Confirm;
