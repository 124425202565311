import { combineReducers } from "redux";
import items from "./items/reducers";
import lists from "./lists/reducers";

import { LOAD_BOARD, GET_BOARD, LOAD_BOARD_VIEW } from "./actions";

const initialData = {
  id: null,
  name: null,
  isLoading: true,
};

export function board(state = initialData, action) {
  switch (action.type) {
    case LOAD_BOARD_VIEW:
    case GET_BOARD:
      return {
        ...initialData,
        isLoading: true,
      };
    case LOAD_BOARD:
      const { board } = action;

      return {
        ...state,
        ...board,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default combineReducers({ board, items, lists });
