import API_ITEMS from "api/items";

import { getLists } from "store/views/item/lists/actions";
import { getComments } from "store/views/item/comments/actions";
import { getHistory } from "store/views/item/history/actions";

import { getUsers } from "store/users/actions";
import { getChecklists } from "./checklists/actions";

import { getItems } from "./items/actions";

export const LOAD_ITEM = "VIEW_ITEM_LOAD_ITEM";

export const LOAD_ITEM_VIEW = "VIEW_ITEM_LOAD_ITEM_VIEW";
export const LOAD_ITEM_VIEW_SUCCESS = "VIEW_ITEM_LOAD_ITEM_VIEW_SUCCESS";
export const LOAD_ITEM_VIEW_FAILED = "VIEW_ITEM_LOAD_ITEM_VIEW_FAILED";

export const GET_ITEM = "VIEW_ITEM_GET_ITEM";
export const GET_ITEM_SUCCESS = "VIEW_ITEM_GET_ITEM_SUCCESS";
export const GET_ITEM_FAILED = "VIEW_ITEM_GET_ITEM_FAILED";

export const UPDATE_ITEM = "VIEW_ITEM_UPDATE_ITEM";
export const UPDATE_ITEM_FAILED = "VIEW_ITEM_UPDATE_ITEM_FAILED";
export const UPDATE_ITEM_SUCCESS = "VIEW_ITEM_UPDATE_ITEM_SUCCESS";

export const loadItem = (item) => {
  return {
    type: LOAD_ITEM,
    item,
  };
};

export const loadView = (itemId) => async (dispatch) => {
  await dispatch({
    type: LOAD_ITEM_VIEW,
    itemId,
  });

  await dispatch(getItem(itemId));
  await dispatch(getItems());

  await dispatch(getComments());
  await dispatch(getHistory());
  await dispatch(getChecklists());

  await dispatch(getLists());
  await dispatch(getUsers());

  await dispatch({
    type: LOAD_ITEM_VIEW_SUCCESS,
    itemId,
  });
};

export const reloadView = () => async (dispatch, getState) => {
  const itemId = getState().views.item.item.id;
  await dispatch(loadView(itemId));
};

export const getItem = (itemId) => async (dispatch) => {
  dispatch({
    type: GET_ITEM,
    itemId,
  });

  const { item } = await API_ITEMS.getById(itemId);

  if (!item) {
    return dispatch({
      type: GET_ITEM_FAILED,
    });
  }

  dispatch({
    type: GET_ITEM_SUCCESS,
  });

  dispatch(loadItem(item));
};

export const updateItem = (item) => async (dispatch) => {
  dispatch({
    type: UPDATE_ITEM,
    item,
  });

  const result = await API_ITEMS.update([item]);

  if (!result) {
    return dispatch({
      type: UPDATE_ITEM_FAILED,
    });
  }

  dispatch({
    type: UPDATE_ITEM_SUCCESS,
  });

  dispatch(reloadView());
};
