import API_ASSETS from "api/assets";
import { Component } from "react";

import Button from "components/button";

export default class Upload extends Component {
  state = { files: null };
  onBrowse(e) {
    this.setState({
      files: e.target.files,
    });
  }

  async onUpload() {
    if (!this.state.files) {
      return;
    }

    const { fileKey } = await API_ASSETS.upload(this.state.files[0]);

    this.props.onUpdate(fileKey);
  }

  render() {
    return (
      <div className="upload">
        <div className="image">
          <img src={this.props.image} />
        </div>
        <div className="uploader">
          <input
            type="file"
            name="file_to_upload"
            onChange={(e) => this.onBrowse(e)}
          />
          <Button onClick={() => this.onUpload()}>Upload</Button>
        </div>
      </div>
    );
  }
}
