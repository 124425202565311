import { Component } from "react";

export default class extends Component {
  get classNames() {
    const classes = ["item"];

    return classes.join(" ");
  }

  render() {
    const { item } = this.props;

    return (
      <div className={this.classNames}>
        <div className="name">
          <strong>NA-{item.id}</strong> {item.name}
        </div>
      </div>
    );
  }
}
