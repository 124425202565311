import { LOAD_COMMENTS, GET_COMMENTS } from "./actions";
import { getById } from "utils/array";

const initialData = {
  byId: {},
  all: [],
  isLoading: true,
};

export function comments(state = initialData, action) {
  switch (action.type) {
    case GET_COMMENTS:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_COMMENTS: {
      return {
        ...state,
        byId: getById(action.comments),
        all: action.comments,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

export default comments;
