import { Component } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { reorderItem, moveItem } from "./utils";

export default class Context extends Component {
  onItemMove(result) {
    const itemId = parseInt(result.draggableId.replace("draggable-", ""));
    const item = this.props.items.byId[itemId];

    if (!item || !result?.destination?.droppableId) {
      return console.warn("No item was found matching the id");
    }

    const source = {
      itemId,
      listId: parseInt(result.source.droppableId.replace("droppable-", "")),
      index: parseInt(result.source.index),
    };

    const destination = {
      listId: parseInt(
        result.destination.droppableId.replace("droppable-", "")
      ),
      index: parseInt(result.destination.index),
    };

    source["items"] = [...this.props.lists.byId[source.listId].items.all];
    destination["items"] = [
      ...this.props.lists.byId[destination.listId].items.all,
    ];

    const isMovingList = source.listId !== destination.listId;

    let items = [];
    if (isMovingList) {
      items = moveItem(source, destination);
    } else {
      items = reorderItem(source.items, source.index, destination.index);
    }

    if (!items) {
      return console.warn("Illegal move buddy", { source, destination });
    }

    this.props.onUpdate?.(items);

    return (
      this.props.onUpdate ??
      console.warn("Draggable context action onUpdate is not hooked up", {
        ...this,
      })
    );
  }

  render() {
    return (
      <DragDropContext onDragEnd={(model) => this.onItemMove(model)}>
        {this.props.children}
      </DragDropContext>
    );
  }
}
