import { LOAD_ITEM, GET_ITEM } from "./actions";
import { getById } from "utils/array";
import { combineReducers } from "redux";

import lists from "./lists/reducers";
import comments from "./comments/reducers";
import history from "./history/reducers";
import checklists from "./checklists/reducers";
import items from "./items/reducers";

const initialData = {
  id: null,
  name: null,
  isLoading: true,
};

export function item(state = initialData, action) {
  switch (action.type) {
    case GET_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_ITEM:
      const item = action.item;

      let { comments, history, ...rest } = item;

      return {
        ...initialData,
        ...rest,
        comments: {
          byId: getById(comments),
          all: comments,
        },
        history: {
          byId: getById(history),
          all: history,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  item,
  items,
  lists,
  comments,
  history,
  checklists,
});
