import API_USERS from "api/users";

export const LOAD_USERS = "LOAD_USERS";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const RELOAD_USERS = "RELOAD_USERS";
export const RELOAD_USERS_SUCCESS = "RELOAD_USERS_SUCCESS";
export const RELOAD_USERS_FAILED = "RELOAD_USERS_FAILED";

export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USERS_FAILED = "UPDATE_USERS_FAILED";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";

export const loadUsers = (users) => {
  return {
    type: LOAD_USERS,
    users,
  };
};

export const reloadUsers = () => async (dispatch, getState) => {
  dispatch({
    type: RELOAD_USERS,
  });

  const users = await API_USERS.get();

  if (!users) {
    return dispatch({
      type: RELOAD_USERS_FAILED,
    });
  }

  dispatch({
    type: RELOAD_USERS_SUCCESS,
  });

  dispatch(loadUsers(users));
};

export const getUsers = () => async (dispatch) => {
  dispatch({
    type: GET_USERS,
  });

  const users = await API_USERS.get();

  if (!users) {
    return dispatch({
      type: GET_USERS_FAILED,
    });
  }

  dispatch({
    type: GET_USERS_SUCCESS,
  });

  dispatch(loadUsers(users));
};
