import API_LISTS from "api/lists";

export const LOAD_LIST = "LOAD_LIST";
export const GET_LIST = "GET_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_LIST_FAILED = "GET_LIST_FAILED";

export const RELOAD_LIST = "RELOAD_LIST";
export const RELOAD_LIST_SUCCESS = "RELOAD_LIST_SUCCESS";
export const RELOAD_LIST_FAILED = "RELOAD_LIST_FAILED";

export const UPDATE_LISTS = "UPDATE_LISTS";
export const UPDATE_LISTS_FAILED = "UPDATE_LISTS_FAILED";
export const UPDATE_LISTS_SUCCESS = "UPDATE_LISTS_SUCCESS";

export const UPDATE_LIST = "UPDATE_LIST";
export const UPDATE_LIST_FAILED = "UPDATE_LIST_FAILED";
export const UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS";

export const loadList = (list) => {
  return {
    type: LOAD_LIST,
    list,
  };
};

export const reloadList = () => async (dispatch, getState) => {
  const listId = getState().list.id;

  dispatch({
    type: RELOAD_LIST,
    listId,
  });

  const { list } = await API_LISTS.getById(listId);

  if (!list) {
    return dispatch({
      type: RELOAD_LIST_FAILED,
    });
  }

  dispatch({
    type: RELOAD_LIST_SUCCESS,
  });

  dispatch(loadList(list));
};

export const getList = (listId) => async (dispatch) => {
  dispatch({
    type: GET_LIST,
    listId,
  });

  const { list } = await API_LISTS.getById(listId);

  if (!list) {
    return dispatch({
      type: GET_LIST_FAILED,
    });
  }

  dispatch({
    type: GET_LIST_SUCCESS,
  });

  dispatch(loadList(list));
};

export const updateList = (list) => async (dispatch) => {
  dispatch({
    type: UPDATE_LIST,
    list,
  });

  const result = await API_LISTS.update([list]);

  if (!result) {
    return dispatch({
      type: UPDATE_LIST_FAILED,
    });
  }

  dispatch({
    type: UPDATE_LIST_SUCCESS,
  });

  dispatch(reloadList());
};
