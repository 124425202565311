import { Component } from "react";

import Item from "./item";

export default class History extends Component {
  render() {
    const { history, users, lists, boards } = this.props;

    return (
      <div className="history">
        {history.all.map((model) => (
          <Item
            model={model}
            users={users}
            lists={lists}
            boards={boards}
            key={`history-item-${model.id}`}
          />
        ))}
      </div>
    );
  }
}
