import { Component } from "react";
import Select from "components/form/select-input";

export default class List extends Component {
  render() {
    const model = this.props.model ?? [];
    const listId = this.props.listId ?? null;

    return (
      <Select
        label="List"
        name="list"
        items={model}
        value={listId}
        onUpdate={(value) => this.props.onUpdate(value)}
      />
    );
  }
}
