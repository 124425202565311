import API_BOARDS from "api/boards";
import { getItems } from "../items/actions";

export const LOAD_BOARDS = "VIEW_BOARDS_LOAD_BOARDS";
export const GET_BOARDS = "VIEW_BOARDS_GET_BOARDS";
export const GET_BOARDS_FAILED = "VIEW_BOARDS_GET_BOARDS_FAILED";
export const GET_BOARDS_SUCCESS = "VIEW_BOARDS_GET_BOARDS_SUCCESS";

export const loadBoards = (boards) => {
  return {
    type: LOAD_BOARDS,
    boards,
  };
};

export const getBoards = () => async (dispatch) => {
  dispatch({
    type: GET_BOARDS,
  });

  let boards = await API_BOARDS.get();

  if (!boards) {
    return dispatch({
      type: GET_BOARDS_FAILED,
    });
  }

  boards = [{ id: 0, name: "Backlog" }, ...boards];

  dispatch({
    type: GET_BOARDS_SUCCESS,
    boards,
  });

  await dispatch(loadBoards(boards));

  for (let i = 0; i < boards.length; i++) {
    await dispatch(getItems(boards[i].id));
  }
};
