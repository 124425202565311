import React, { Component } from "react";

import "./content-editable.scss";

export default class ContentEditable extends Component {
  constructor() {
    super(...arguments);

    this.element = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.html !== this.element.current.innerHTML;
  }

  onInput() {
    const html = this.element.current.innerHTML;

    if (html !== this.lastHtml) {
      this.props.onChange(html);
    }

    this.lastHtml = html;
  }

  render() {
    return (
      <div
        className="content-editable"
        ref={this.element}
        onInput={() => this.onInput()}
        onBlur={() => this.onInput()}
        contentEditable
        dangerouslySetInnerHTML={{ __html: this.props.html }}
      ></div>
    );
  }
}
