import API_LISTS from "api/lists";

export const LOAD_LISTS = "VIEW_BOARD_LOAD_LISTS";

export const CLEAR_LISTS = "VIEW_BOARD_CLEAR_LISTS";

export const GET_LISTS = "VIEW_BOARD_GET_LISTS";
export const GET_LISTS_SUCCESS = "VIEW_BOARD_GET_LISTS_SUCCESS";
export const GET_LISTS_FAILED = "VIEW_BOARD_GET_LISTS_FAILED";

export const UPDATE_LISTSS = "VIEW_BOARD_UPDATE_LISTSS";
export const UPDATE_LISTSS_FAILED = "VIEW_BOARD_UPDATE_LISTSS_FAILED";
export const UPDATE_LISTSS_SUCCESS = "VIEW_BOARD_UPDATE_LISTSS_SUCCESS";

export const UPDATE_LISTS = "VIEW_BOARD_UPDATE_LISTS";
export const UPDATE_LISTS_FAILED = "VIEW_BOARD_UPDATE_LISTS_FAILED";
export const UPDATE_LISTS_SUCCESS = "VIEW_BOARD_UPDATE_LISTS_SUCCESS";

export const clearLists = () => {
  return {
    type: CLEAR_LISTS,
  };
};

export const loadLists = (lists) => {
  return {
    type: LOAD_LISTS,
    lists,
  };
};

export const getLists = () => async (dispatch) => {
  dispatch({
    type: GET_LISTS,
  });

  const lists = await API_LISTS.get();

  if (!lists) {
    return dispatch({
      type: GET_LISTS_FAILED,
    });
  }

  dispatch({
    type: GET_LISTS_SUCCESS,
  });

  dispatch(loadLists(lists));
};

export const updateLists = (lists) => async (dispatch) => {
  dispatch({
    type: UPDATE_LISTS,
    lists,
  });

  const result = await API_LISTS.update(lists);

  if (!result) {
    return dispatch({
      type: UPDATE_LISTS_FAILED,
    });
  }

  dispatch({
    type: UPDATE_LISTS_SUCCESS,
  });
};
