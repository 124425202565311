import { Component } from "react";

import SelectInput from "components/form/select-input";
import Button from "components/button";
import Icon from "components/icon";

import TaskSearch from "components/task-search";

import ConfirmService from "services/dialog/confirm";

const TYPE_BLOCKING = 1;
const TYPE_BLOCKED = 2;
const TYPE_LINKED = 3;

const keyTranslator = {
  [TYPE_BLOCKING]: "blocking",
  [TYPE_BLOCKED]: "blocked",
  [TYPE_LINKED]: "linked",
};

class Item extends Component {
  get classNames() {
    const classes = ["item"];
    const list = this.lists.byId?.[this.item.listId];

    if (list && list.isDone) {
      classes.push("is-done");
    }

    return classes.join(" ");
  }

  get item() {
    return this.props.item;
  }

  get lists() {
    return this.props.lists;
  }

  render() {
    const { lists, item } = this.props;
    const { id, name, listId } = item;

    return (
      <div className={this.classNames} onClick={() => this.props.onClick?.()}>
        <div className="item-wrapper">
          <div className="name">
            <strong>[NA-{id}]</strong> {name}
          </div>
          <div className="list">
            <span>{lists.byId?.[listId]?.name}</span>
          </div>
          <Icon
            onClick={async (e) => {
              e.stopPropagation();
              const confirm = await ConfirmService.confirm({
                message:
                  "Are you sure you want to delete this linked issue? This action cannot be undone.",
              });
              if (confirm) {
                return this.props.onDelete();
              }
            }}
          >
            close
          </Icon>
        </div>
      </div>
    );
  }
}

export default class extends Component {
  state = {
    selectedItems: [],
    linkedBy: 1,
  };

  get linked() {
    return this.items.linked ?? [];
  }

  get blocked() {
    return this.items.blocked ?? [];
  }

  get blocking() {
    return this.items.blocking ?? [];
  }

  get items() {
    return this.props.items ?? {};
  }

  get lists() {
    return this.props.lists ?? { byId: {}, all: [] };
  }

  onInput(column, value) {
    this.setState({ [column]: value });
  }

  async onAdd() {
    const { selectedItems, linkedBy } = this.state;
    await this.props.onAdd({
      type: keyTranslator[linkedBy],
      items: selectedItems,
    });
    this.setState({
      selectedItems: [],
    });
  }

  onDelete(item) {
    const { linkId } = item;
    this.props.onDelete(linkId);
  }

  render() {
    const { lists, linked, blocked, blocking } = this;

    return (
      <div className="linked-tasks">
        <h1>Linked issues</h1>
        {blocking.length > 0 ? (
          <div className="list">
            <div className="title">Is blocking</div>
            <div className="items">
              {blocking.map((item) => (
                <Item
                  item={item}
                  lists={lists}
                  key={`blocking-item-${item.id}`}
                  onClick={() => this.props.onNavigate(`../item/${item.id}`)}
                  onDelete={() => this.onDelete(item)}
                />
              ))}
            </div>
          </div>
        ) : null}
        {blocked.length > 0 ? (
          <div className="list">
            <div className="title">Is blocked by</div>
            <div className="items">
              {blocked.map((item) => (
                <Item
                  item={item}
                  lists={lists}
                  key={`blocked-item-${item.id}`}
                  onClick={() => this.props.onNavigate(`../item/${item.id}`)}
                  onDelete={() => this.onDelete(item)}
                />
              ))}
            </div>
          </div>
        ) : null}
        {linked.length > 0 ? (
          <div className="list">
            <div className="title">Is related to</div>
            <div className="items">
              {linked.map((item) => (
                <Item
                  item={item}
                  lists={lists}
                  key={`linking-item-${item.id}`}
                  onClick={() => this.props.onNavigate(`../item/${item.id}`)}
                  onDelete={() => this.onDelete(item)}
                />
              ))}
            </div>
          </div>
        ) : null}
        <div className="add">
          <label>This task is</label>
          <div className="linked-by">
            <SelectInput
              name="linked_by"
              value={this.state.linkedBy}
              items={[
                {
                  id: TYPE_BLOCKING,
                  name: "Blocking",
                },
                {
                  id: TYPE_BLOCKED,
                  name: "Blocked by",
                },
                {
                  id: TYPE_LINKED,
                  name: "Related to",
                },
              ]}
              onUpdate={(value) => this.onInput("linkedBy", value)}
            />
          </div>
          <TaskSearch
            value={this.state.selectedItems}
            onUpdate={(items) => this.onInput("selectedItems", items)}
          />
          <Button onClick={() => this.onAdd()}>Add</Button>
        </div>
      </div>
    );
  }
}
