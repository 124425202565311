import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

const get = () =>
  axios
    .get(`${API_URL}/users.get/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const update = (user) =>
  axios
    .post(`${API_URL}/users.update/`, { user }, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export default { get, update };
