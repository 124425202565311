import { Component } from "react";
import Select from "components/form/select-input";

const priorities = [
  { id: 0, name: "Low Priority" },
  { id: 1, name: "Medium Priority" },
  { id: 2, name: "High Priority" },
];

export default class Priority extends Component {
  render() {
    const priority = this.props.priority;

    return (
      <Select
        label="Priority"
        name="priority"
        items={priorities}
        value={priority}
        onUpdate={(value) => this.props.onUpdate(value)}
      />
    );
  }
}
