import React, { Component } from "react";

import Dialog from "components/dialog";
import Assignee from "./assignee";
import Checklist from "./checklist";
import Comments from "./comments";
import Description from "./description";
import History from "./history";
import List from "./list";
import Board from "./board";
import Name from "./name";
import Priority from "./priority";
import LinkedTasks from "./linked-tasks";

import Tabs from "components/tabs";

import { Beforeunload } from "react-beforeunload";

import "./styles/item.scss";

const parseId = (id) => {
  const isInteger = id > 0;
  if (!isInteger) {
    return null;
  }

  return parseInt(id);
};

export default class extends Component {
  state = {
    item: {},
  };

  constructor() {
    super(...arguments);

    this.scrollableRef = React.createRef();

    const itemId = parseId(this.props.params.itemId);
    this.props.loadView(itemId);
  }

  onClose() {
    this.onSave();

    if (this.props.useDialog) {
      this.props.navigate("../", { replace: true });
    }
  }

  async onNavigate(route) {
    await this.onSave();
    return this.props.navigate(route);
  }

  get hasChanges() {
    return Object.keys(this.state.item).length > 0;
  }

  async onSave(override = false) {
    if (this.hasChanges || override) {
      await this.props.updateItem({
        id: this.props.item.id,
        ...this.state.item,
      });
    }

    await this.setState({
      item: {},
    });
  }

  async onUpdate(column, value, forceSave) {
    await this.setState({
      item: {
        ...this.state.item,
        [column]: value,
      },
    });
  }

  render() {
    const { item, useDialog } = this.props;

    const dialogTitle = `Task`;
    const dialogSubtitle = `${item.name} (NA-${item.id})`;

    return useDialog ? (
      <Dialog
        title={dialogTitle}
        subtitle={dialogSubtitle}
        onClose={() => this.onClose()}
        fullWidth={true}
        scrollable={false}
      >
        {this.renderView()}
      </Dialog>
    ) : (
      this.renderView()
    );
  }

  get currentUser() {
    return this.props.currentUser;
  }

  get item() {
    return this.props.item;
  }

  get items() {
    return this.props.items;
  }

  get lists() {
    return this.props.lists;
  }

  get comments() {
    return this.props.comments;
  }

  get history() {
    return this.props.history;
  }

  get users() {
    return this.props.users;
  }

  get boards() {
    return this.props.boards;
  }

  get checklists() {
    return this.props.checklists;
  }

  get isLoading() {
    return this.props.isLoading ?? true;
  }

  async componentDidUpdate(prevProps, prevState) {
    const prevItem = prevProps.params.itemId;
    const currentItem = this.props.params.itemId;

    const hasChangedItemId = !(prevItem === currentItem);

    if (hasChangedItemId) {
      const itemId = parseId(currentItem);
      await this.props.loadView(itemId);

      setTimeout(() => {
        this.scrollableRef.current.scrollTop = 0;
      }, 5000);
    }
  }

  renderView() {
    let {
      item,
      items,
      lists,
      users,
      comments,
      history,
      boards,
      currentUser,
      checklists,
    } = this;
    item = { ...item, ...this.state.item };

    if (this.isLoading) {
      return;
    }

    return (
      <div className="edit-item">
        <div
          className="column column-1"
          ref={this.scrollableRef}
          key={`item-${item.id}`}
        >
          {this.isLoading ? (
            <div className="loading">Loading</div>
          ) : (
            <div className="column-wrapper">
              <Name
                name={item.name}
                onUpdate={(name) => this.onUpdate("name", name)}
              />
              <Description
                description={item.description}
                onUpdate={(description) =>
                  this.onUpdate("description", description)
                }
              />
              <Checklist
                items={checklists}
                onUpdate={this.props.updateChecklists}
                onDelete={this.props.deleteChecklist}
                onAdd={this.props.addChecklist}
              />
              <LinkedTasks
                items={items}
                lists={lists}
                onNavigate={(route) => this.onNavigate(route)}
                onAdd={this.props.addLink}
                onDelete={this.props.deleteLink}
              />
              <Tabs
                selectedId={"comments"}
                items={{
                  comments: {
                    id: "comments",
                    name: "Comments",
                    element: (
                      <Comments
                        comments={comments}
                        users={users}
                        currentUser={currentUser}
                        onComment={this.props.addComment}
                        onCommentUpdate={this.props.updateComment}
                        onCommentDelete={this.props.deleteComment}
                      />
                    ),
                  },
                  history: {
                    id: "history",
                    name: "History",
                    element: (
                      <History
                        history={history}
                        users={users}
                        lists={lists}
                        boards={boards}
                      />
                    ),
                  },
                }}
              />
            </div>
          )}
        </div>
        <div className="column column-2">
          {this.isLoading ? (
            <p>Loading</p>
          ) : (
            <div className="column-wrapper">
              <Board
                boardId={item.boardId}
                onUpdate={(boardId) => this.onUpdate("boardId", boardId)}
                model={boards.all}
              />
              <List
                listId={item.listId}
                onUpdate={(listId) => this.onUpdate("listId", listId)}
                model={lists.all}
              />
              <Assignee
                assigneeId={item.assigneeId}
                onUpdate={(assigneeId) =>
                  this.onUpdate("assigneeId", assigneeId)
                }
                model={users.all}
              />
              <Priority
                priority={item.priority}
                onUpdate={(priority) => this.onUpdate("priority", priority)}
              />
              <span className="task-id">
                <h5>NA-{item.id}</h5>
              </span>
            </div>
          )}
        </div>
        {this.hasChanges && (
          <Beforeunload onBeforeunload={(e) => this.onSave(true)} />
        )}
      </div>
    );
  }
}

// import { getItemHistory } from "api/history";
// import {
//   get as getComments,
//   comment as postComment,
//   update as updateComment,
//   remove as removeComment,
// } from "api/comments";

// async onComment(comment) {
//   await postComment("items", this.props.item.id, comment);
//   this.getComments();
// }

// async onCommentUpdate(comment) {
//   await updateComment(comment);
//   this.getComments();
// }

// async onCommentDelete(comment) {
//   await removeComment(comment);
//   this.getComments();
// }
