import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

const get = () =>
  axios
    .get(`${API_URL}/boards.get/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const getById = (boardId) =>
  axios
    .get(`${API_URL}/boards.get/${boardId}`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const update = (boards) =>
  axios
    .post(`${API_URL}/boards.update`, { boards }, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export default { get, update, getById };
