import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

export const login = (email, password) =>
  axios
    .post(`${API_URL}/auth.login/`, { email, password }, getAxiosOptions(false))
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export const authenticate = () =>
  axios
    .get(`${API_URL}/auth.verify/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);
