import { Component } from "react";
import "./icon.scss";

export default class extends Component {
  get icon() {
    return this.props.children ?? this.props.icon;
  }

  render() {
    return (
      <span className="material-icons" onClick={this.props.onClick}>
        {this.icon}
      </span>
    );
  }
}
