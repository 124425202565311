import API_HISTORY from "api/history";

export const LOAD_HISTORY = "VIEW_ITEM_LOAD_HISTORY";

export const GET_HISTORY = "VIEW_ITEM_GET_HISTORY";
export const GET_HISTORY_SUCCESS = "VIEW_ITEM_GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAILED = "VIEW_ITEM_GET_HISTORY_FAILED";

const getItemId = (getState) => getState().views.item.item.id;

export const loadHistory = (history) => {
  return {
    type: LOAD_HISTORY,
    history,
  };
};

export const getHistory = () => async (dispatch, getState) => {
  const itemId = getItemId(getState);

  dispatch({
    type: GET_HISTORY,
    itemId,
  });

  const history = await API_HISTORY.getItemHistory(itemId);

  if (!history) {
    return dispatch({
      type: GET_HISTORY_FAILED,
    });
  }

  dispatch({
    type: GET_HISTORY_SUCCESS,
  });

  dispatch(loadHistory(history));
};
