import { Component } from "react";
import "./auth.scss";

import Login from "./login";

export default class extends Component {
  render() {
    return (
      <div className="auth">
        <Login />
      </div>
    );
  }
}
