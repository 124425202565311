import API_ITEMS from "api/items";

export const LOAD_ITEMS = "VIEW_BOARDS_LOAD_ITEMS";

export const CLEAR_ITEMS = "VIEW_BOARDS_CLEAR_ITEMS";

export const GET_ITEMS = "VIEW_BOARDS_GET_ITEMS";
export const GET_ITEMS_FAILED = "VIEW_BOARDS_GET_ITEMS_FAILED";
export const GET_ITEMS_SUCCESS = "VIEW_BOARDS_GET_ITEMS_SUCCESS";

export const loadItems = (boardId, items) => {
  return {
    type: LOAD_ITEMS,
    boardId,
    items,
  };
};

export const getItems = (boardId) => async (dispatch, getState) => {
  dispatch({
    type: GET_ITEMS,
    boardId,
  });

  const items = await API_ITEMS.getByBoardId(boardId);

  if (!items) {
    return dispatch({
      type: GET_ITEMS_FAILED,
    });
  }

  dispatch({
    type: GET_ITEMS_SUCCESS,
  });

  dispatch(loadItems(boardId, items));
};
