import { LOAD_LISTS, GET_LISTS } from "./actions";
import { getById } from "utils/array";

const initialData = {
  byId: {},
  all: [],
  isLoading: true,
};

export function item(state = initialData, action) {
  switch (action.type) {
    case GET_LISTS:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_LISTS: {
      return {
        ...state,
        byId: getById(action.lists),
        all: action.lists,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

export default item;
