import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

const update = (items) =>
  axios
    .post(`${API_URL}/items.update`, { items }, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const search = (searchText) =>
  axios
    .get(`${API_URL}/items.search/${searchText}`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const get = () =>
  axios
    .get(`${API_URL}/items.get/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const getById = (itemId) =>
  axios
    .get(`${API_URL}/items.get/${itemId}/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const getByBoardId = (boardId) =>
  axios
    .get(`${API_URL}/items.get/board/${boardId}/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const getLinkedItems = (itemId) =>
  axios
    .get(`${API_URL}/items.get/linked/${itemId}/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export const addLinkedItems = ({ itemId, type, items }) =>
  axios
    .post(
      `${API_URL}/items.post/${itemId}/linked/${type}`,
      { items },
      getAxiosOptions()
    )
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export const removeLink = (linkId) =>
  axios
    .delete(`${API_URL}/items.remove/linked/${linkId}/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export default {
  update,
  get,
  search,
  removeLink,
  getLinkedItems,
  addLinkedItems,
  getById,
  getByBoardId,
};
