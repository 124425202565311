import { Component } from "react";
import "./button-icon.scss";

import Icon from "components/icon";

export default class extends Component {
  get classNames() {
    const classes = ["button-icon"];

    return classes.join(" ");
  }

  render() {
    return (
      <div className={this.classNames} onClick={() => this.props.onClick?.()}>
        <Icon>{this.props.children}</Icon>
      </div>
    );
  }
}
