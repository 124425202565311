import API_ITEMS from "api/items";

export const LOAD_ITEMS = "VIEW_ITEM_LOAD_ITEMS";

export const GET_ITEMS = "VIEW_ITEM_GET_ITEMS";
export const GET_ITEMS_FAILED = "VIEW_ITEM_GET_ITEMS_FAILED";
export const GET_ITEMS_SUCCESS = "VIEW_ITEM_GET_ITEMS_SUCCESS";

export const ADD_ITEM_LINK = "VIEW_ITEM_ADD_ITEM_LINK";
export const ADD_ITEM_LINK_FAILED = "VIEW_ITEM_ADD_ITEM_LINK_FAILED";
export const ADD_ITEM_LINK_SUCCESS = "VIEW_ITEM_ADD_ITEM_LINK_SUCCESS";

export const DELETE_ITEM_LINK = "VIEW_ITEM_DELETE_ITEM_LINK";
export const DELETE_ITEM_LINK_FAILED = "VIEW_ITEM_DELETE_ITEM_LINK_FAILED";
export const DELETE_ITEM_LINK_SUCCESS = "VIEW_ITEM_DELETE_ITEM_LINK_SUCCESS";

const getItemId = (getState) => getState().views.item.item.id;

export const loadItems = (items) => {
  return {
    type: LOAD_ITEMS,
    items,
  };
};

export const getItems = () => async (dispatch, getState) => {
  const itemId = getItemId(getState);
  const items = await API_ITEMS.getLinkedItems(itemId);

  dispatch(loadItems(items));
};

export const addLink = (link) => async (dispatch, getState) => {
  const itemId = getItemId(getState);

  dispatch({
    type: ADD_ITEM_LINK,
    link: { itemId, ...link },
  });

  await API_ITEMS.addLinkedItems({ itemId, ...link });

  dispatch({
    type: ADD_ITEM_LINK_SUCCESS,
  });

  dispatch(getItems());
};

export const deleteLink = (linkId) => async (dispatch) => {
  dispatch({
    type: DELETE_ITEM_LINK,
    linkId,
  });
  await API_ITEMS.removeLink(linkId);
  dispatch({
    type: DELETE_ITEM_LINK_SUCCESS,
  });
  dispatch(getItems());
};
