import React, { Component, useCallback, useEffect } from "react";

import "./dialog.scss";

function Escaped(props) {
  const onEscape = useCallback((event) => {
    if (event.keyCode === 27) {
      props.onEscape();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onEscape);
    return () => {
      document.removeEventListener("keydown", onEscape);
    };
  }, [onEscape]);

  return null;
}

export default class extends Component {
  get classNames() {
    const classes = ["dialog-container"];

    if (this.size) {
      classes.push(`size-${this.size}`);
    }

    if (this.isFullWidth) {
      classes.push("full-width");
    }

    if (!this.isScrollable) {
      classes.push("no-scroll");
    }

    return classes.join(" ");
  }

  get size() {
    return this.props.size ?? null;
  }

  get isFullWidth() {
    return Boolean(this.props.fullWidth ?? false);
  }
  get isScrollable() {
    return Boolean(this.props.scrollable ?? true);
  }

  get hasClose() {
    return Boolean(this.props.hasClose ?? true);
  }

  onClick(e) {
    const target = e.target;

    const isDialogWrapper = Boolean(
      target?.getAttribute("class") === "dialog-wrapper"
    );

    if (isDialogWrapper) {
      this.props?.onClose?.();
    }
  }

  render() {
    return (
      <div className={this.classNames} onClick={(e) => this.onClick(e)}>
        <Escaped onEscape={this.props.onClose} />
        <div className="dialog-wrapper">
          <div className="dialog">
            <div className="dialog-header">
              <span className="title">
                {this.props.title}{" "}
                {this.props.subtitle ? (
                  <span>{this.props.subtitle}</span>
                ) : null}
              </span>
              {this.hasClose ? (
                <span className="close" onClick={this.props.onClose}>
                  X
                </span>
              ) : null}
            </div>

            <div className="dialog-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
