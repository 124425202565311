import { Component } from "react";

import Button from "components/button";
import Archive from "routes/board/actions/archive";

export default class extends Component {
  state = {
    isArchiveBoardDialogActive: false,
  };

  get currentOrDefaultBoardName() {
    return this.currentOrDefaultBoard.name ?? "No boards";
  }

  get currentOrDefaultBoard() {
    const hasCurrentBoard = this.currentBoard.id;
    return hasCurrentBoard ? this.currentBoard : this.boards.all[0] ?? {};
  }

  get currentBoardIndex() {
    return this.boards.all.findIndex(
      (board) => board.id === this.currentOrDefaultBoard.id
    );
  }

  get prevBoardId() {
    return this.boards.all[this.currentBoardIndex - 1]?.id ?? null;
  }

  get nextBoardId() {
    return this.boards.all[this.currentBoardIndex + 1]?.id ?? null;
  }

  get boards() {
    return this.props.boards;
  }

  get currentBoard() {
    return this.props.currentBoard;
  }

  get isBoardSelected() {
    return this.props.isBoardSelected;
  }

  get isBoardAvailable() {
    return Boolean(this.currentBoard.id);
  }

  get currentUser() {
    return this.props.currentUser;
  }

  onArchiveBoard() {
    this.setState({
      isArchiveBoardDialogActive: true,
    });
  }

  onArchiveBoardClose() {
    this.setState({
      isArchiveBoardDialogActive: false,
    });
  }

  render() {
    const { onNavigateTo } = this.props;
    const {
      isBoardSelected,
      prevBoardId,
      nextBoardId,
      currentOrDefaultBoard,
      currentOrDefaultBoardName,
      isBoardAvailable,
    } = this;

    const { isArchiveBoardDialogActive } = this.state;

    return (
      <div className="navigation-board">
        <Button
          isEnabled={Boolean(prevBoardId)}
          onClick={() => onNavigateTo(`board/${prevBoardId}/`)}
          icon={"navigate_before"}
        />
        <Button
          onClick={() => onNavigateTo(`board/${currentOrDefaultBoard.id}/`)}
          isActive={isBoardSelected && isBoardAvailable}
        >
          {currentOrDefaultBoardName}
        </Button>

        <Button
          isEnabled={Boolean(nextBoardId)}
          onClick={() => onNavigateTo(`board/${nextBoardId}/`)}
          icon={"navigate_next"}
        />

        {isBoardSelected && isBoardAvailable && this.currentUser.isAdmin ? (
          <div className="navigation-board-actions">
            <Button onClick={() => this.onArchiveBoard()}>Archive Board</Button>
            {isArchiveBoardDialogActive ? (
              <Archive onClose={() => this.onArchiveBoardClose()} />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
