import { Component } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import Accordion from "components/accordion";

import Context from "./components/context";
import List from "./components/list";

import Board from "./board";
import Item from "./item";

import "./items.scss";

export default class Backlog extends Component {
  constructor() {
    super(...arguments);

    this.props.loadView();
  }

  onUpdate(items) {
    console.log("onUpdate", items);
  }

  get boards() {
    return this.props.boards ?? { byId: [], all: [] };
  }

  render() {
    const [backlog, ...boards] = this.boards.all;

    if (!backlog || !boards.length) {
      return null;
    }

    return (
      <div className="items">
        <Context onUpdate={(items) => this.onUpdate(items)}>
          {boards.map((board) => (
            <Board board={board} key={`board-${board.id}`}>
              <List list-id={board.id}>
                {board.items.all.map((item) => (
                  <Item
                    item-id={item.id}
                    key={`board-${board.id}-item-${item.id}`}
                    item={item}
                  />
                ))}
              </List>
            </Board>
          ))}

          <Board board={backlog}>
            <List list-id="0">
              {backlog.items.all.map((item) => (
                <Item
                  item-id={item.id}
                  key={`board-0-item-${item.id}`}
                  item={item}
                />
              ))}
            </List>
          </Board>
        </Context>
        {/* 
        
      Default view

      Active Boards
      -- Board
        -- Items
      Backlog
      -- Items

      -- Completed View

      Completed Boards
      -- Board
        -- Items

        */}
      </div>
    );
  }
}
