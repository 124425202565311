import { Component } from "react";

import "./accordion.scss";

export default class Accordion extends Component {
  state = { isOpen: true };

  get className() {
    return this.props?.className;
  }

  get classNames() {
    const classes = ["accordion", this.className];

    if (this.state.isOpen) {
      classes.push("is-open");
    }

    return classes.join(" ");
  }

  onToggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { title, actions, children, height } = this.props;

    return (
      <div className={this.classNames}>
        <div className="accordion-title" onClick={() => this.onToggle()}>
          <span>{title}</span>
          {(actions ?? []).map((action) => (
            <span
              className="action"
              onClick={(e) => {
                e.stopPropagation();
                return action.callback();
              }}
            >
              {action.name}
            </span>
          ))}
        </div>
        <div className="accordion-body" style={height}>
          {children}
        </div>
      </div>
    );
  }
}
