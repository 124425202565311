import { Component } from "react";
import "./app.scss";

import Auth from "./auth";

import Profile from "components/user/profile";
import Picture from "components/user/picture";

import BoardNavigation from "./navigation/board";

import { Outlet } from "react-router-dom";

import Button from "components/button";

export default class extends Component {
  state = { isProfileVisible: false };

  constructor() {
    super(...arguments);
    this.props.getBoards();
  }

  onLogout() {
    this.props.unassignLoggedInUser();
  }

  onOpenProfile() {
    this.setState({ isProfileVisible: true });
  }

  onCloseProfile() {
    this.setState({ isProfileVisible: false });
  }

  renderAsGuest() {
    return <Auth />;
  }

  renderAsUser() {
    return (
      <>
        <Outlet />
      </>
    );
  }

  isProfileVisible() {
    const { isProfileVisible } = this.state;
    if (!isProfileVisible) {
      return null;
    }

    return <Profile onClose={() => this.onCloseProfile()} />;
  }

  onNavigateTo(to) {
    const { navigate } = this.props;
    return navigate(to);
  }

  componentDidUpdate(prevProps) {
    const isLoggedIn = prevProps.user.id > 0;
    if (!isLoggedIn && this.isLoggedIn) {
      this.props.getBoards();
    }
  }

  get isLoggedIn() {
    return this.user?.id > 0;
  }

  get user() {
    return this.props.user;
  }

  get boards() {
    return this.props.boards;
  }

  get currentBoard() {
    return this.props.currentBoard;
  }

  render() {
    const { user, isLoggedIn, currentBoard, boards } = this;

    const pathname = this.props.location.pathname;
    const isItemsSelected = pathname.includes("/items/");
    const isBoardSelected = pathname.includes("/board/");
    const isAccountsSelected = pathname.includes("/accounts/");

    return (
      <div className="app">
        {isLoggedIn ? (
          <div className="header">
            <div className="logo">
              <strong>NA Todo</strong>
            </div>
            <div className="navigation">
              {user.isAdmin ? (
                <Button
                  onClick={() => this.onNavigateTo("items/")}
                  isActive={isItemsSelected}
                >
                  Items
                </Button>
              ) : null}

              <BoardNavigation
                isBoardSelected={isBoardSelected}
                currentBoard={currentBoard}
                currentUser={user}
                boards={boards}
                onNavigateTo={(route) => this.onNavigateTo(route)}
              />

              {user.isAdmin ? (
                <Button
                  onClick={() => this.onNavigateTo("accounts/")}
                  isActive={isAccountsSelected}
                >
                  Accounts
                </Button>
              ) : null}
            </div>
            <div className="user-holder">
              <Picture
                userId={user.id}
                hasName={true}
                onClick={() => this.onOpenProfile()}
              />
              <span>|</span>
              <a onClick={() => this.onLogout()}>Logout</a>
            </div>
          </div>
        ) : null}
        <div className="body">
          {isLoggedIn ? this.renderAsUser() : this.renderAsGuest()}
        </div>
        {this.isProfileVisible()}
      </div>
    );
  }
}
