import { Component } from "react";
import Select from "components/form/select-input";

export default class Assignee extends Component {
  render() {
    const model = this.props.model ?? [];
    const assigneeId = this.props.assigneeId ?? null;

    return (
      <Select
        label="Assignee"
        name="assignee"
        items={[{ name: "No one assigned" }, ...model]}
        value={assigneeId}
        onUpdate={(value) => this.props.onUpdate(value)}
      />
    );
  }
}
