import { connect } from "react-redux";
import Token from "./token";

import { getUser } from "store/user/actions";

function mapStateToProps(state) {
  return { token: state.user.token };
}

const mapDispatchToProps = { getUser };

export default connect(mapStateToProps, mapDispatchToProps)(Token);
