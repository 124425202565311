import { Component } from "react";

import Checklist from "components/checklist";

export const reorderItem = (list, startIndex, endIndex) => {
  const [item] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, item);
  return [...resetIndexes(list)];
};

export const resetIndexes = (items, excludedId = null) =>
  items.reduce(
    (agg, item, index) =>
      excludedId !== item.id && item.index === index
        ? agg
        : [
            ...agg,
            {
              ...item,
              index,
            },
          ],
    []
  );

export default class extends Component {
  get items() {
    return (
      this.props.items ?? {
        byId: {},
        all: [],
      }
    );
  }

  onAdd() {
    console.log("Checklist::onAdd");
    const index =
      (this.items.all.reduce(
        (agg, item) => (item.index > agg ? item.index : agg),
        0
      ) ?? 0) + 1;

    this.props.onAdd({
      description: "",
      isChecked: false,
      index,
    });
  }

  onMove(item) {
    const items = reorderItem(this.items.all, item.index, item.newIndex).map(
      (item) => ({ id: item.id, index: item.index })
    );

    this.props.onUpdate(items);
  }

  onUpdate(item) {
    this.props.onUpdate([item]);
  }

  onDelete(item) {
    this.props.onDelete(item);
  }

  render() {
    const { items } = this;

    return (
      <Checklist
        items={items.all}
        onAdd={() => this.onAdd()}
        onMove={(item) => this.onMove(item)}
        onUpdate={(item) => this.onUpdate(item)}
        onDelete={(item) => this.onDelete(item)}
      />
    );
  }
}
