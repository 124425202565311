import API_ITEMS from "api/items";

export const LOAD_ITEMS = "VIEW_BOARD_LOAD_ITEMS";

export const CLEAR_ITEMS = "VIEW_BOARD_CLEAR_ITEMS";

export const GET_ITEMS = "VIEW_BOARD_GET_ITEMS";
export const GET_ITEMS_FAILED = "VIEW_BOARD_GET_ITEMS_FAILED";
export const GET_ITEMS_SUCCESS = "VIEW_BOARD_GET_ITEMS_SUCCESS";

export const UPDATE_ITEMS = "VIEW_BOARD_UPDATE_ITEMS";
export const UPDATE_ITEMS_FAILED = "VIEW_BOARD_UPDATE_ITEMS_FAILED";
export const UPDATE_ITEMS_SUCCESS = "VIEW_BOARD_UPDATE_ITEMS_SUCCESS";

const getBoardId = (getState) => getState().views.board.board.id;

export const clearItems = () => {
  return {
    type: CLEAR_ITEMS,
  };
};

export const loadItems = (items) => {
  return {
    type: LOAD_ITEMS,
    items,
  };
};

export const getItems = () => async (dispatch, getState) => {
  const boardId = getBoardId(getState);

  dispatch({
    type: GET_ITEMS,
    boardId,
  });

  const items = await API_ITEMS.getByBoardId(boardId);

  if (!items) {
    return dispatch({
      type: GET_ITEMS_FAILED,
    });
  }

  dispatch({
    type: GET_ITEMS_SUCCESS,
  });

  dispatch(loadItems(items));
};

export const updateItems = (items) => async (dispatch) => {
  dispatch({
    type: UPDATE_ITEMS,
    items,
  });

  const result = await API_ITEMS.update(items);

  if (result) {
    dispatch({
      type: UPDATE_ITEMS_SUCCESS,
    });
  } else {
    return dispatch({
      type: UPDATE_ITEMS_FAILED,
    });
  }

  dispatch(getItems());
};
