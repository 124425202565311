import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { loadView, updateView } from "store/views/board/actions";

import Board from "./board.js";

function Wrapper(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Board {...props} params={params} navigate={navigate} location={location} />
  );
}

function mapStateToProps(state) {
  return {
    ...state.views.board,
  };
}

const mapDispatchToProps = { loadView, updateView };

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
