import { Component } from "react";
import Icon from "components/icon";
import Button from "components/button";
import TextEditor from "components/form/text-editor";
import "./checkbox.scss";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import ConfirmService from "services/dialog/confirm";

class Item extends Component {
  get classNames() {
    const classes = ["item"];

    if (this.isChecked) {
      classes.push("is-checked");
    }

    return classes.join(" ");
  }

  get isChecked() {
    return this.item.isChecked;
  }

  get description() {
    return this.item.description;
  }

  get item() {
    return (
      this.props.item ?? {
        id: null,
        description: null,
        isChecked: false,
      }
    );
  }

  get isEditing() {
    return this.item.isEditing ?? false;
  }

  onCheck(isChecked = !this.isChecked, id = this.item.id) {
    return this.props.onUpdate({ id, isChecked });
  }

  onDescription(description = this.description, id = this.item.id) {
    return this.props.onUpdate({ id, description });
  }

  async onDelete(id = this.item.id) {
    const confirm = await ConfirmService.confirm({
      message:
        "Are you sure you want to delete this checklist item? This action cannot be undone.",
    });

    if (confirm) {
      return this.props.onDelete({ id });
    }
  }

  render() {
    const { description } = this;

    return (
      <div className={this.classNames}>
        <div className="checkbox">
          <div className="wrapper">
            <div className="action delete" onClick={() => this.onCheck()}>
              <Icon>done</Icon>
            </div>
          </div>
        </div>
        <div className="description">
          <div className="wrapper">
            <TextEditor
              hasToolbar={false}
              name="description"
              data={description}
              onChange={(value) => this.onDescription(value)}
            />
          </div>
        </div>
        <div className="actions">
          <div className="wrapper">
            <div className="action delete" onClick={() => this.onDelete()}>
              <Icon>delete</Icon>
            </div>
            <div className="action move">
              <Icon>drag_indicator</Icon>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class extends Component {
  get items() {
    return this.props.items ?? [];
  }

  onAdd() {
    return this.props.onAdd();
  }

  onUpdate(item) {
    return this.props.onUpdate(item);
  }

  onDelete(item) {
    return this.props.onDelete(item);
  }

  onMove(result) {
    const itemId = parseInt(result.draggableId.replace("draggable-", ""));
    const item = this.items.find((item) => item.id === itemId);

    if (!item || !(result?.destination?.index >= 0)) {
      return console.warn("No destination index");
    }

    return this.props.onMove({
      ...item,
      newIndex: result.destination.index,
    });
  }

  render() {
    const { items } = this;

    return (
      <div className="checklist">
        <h1>Checklist</h1>
        <DragDropContext onDragEnd={(model) => this.onMove(model)}>
          <Droppable droppableId={`droppable-checklist`} direction="vertical">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <Draggable
                    key={`checkbox-${item.id}`}
                    draggableId={`draggable-${item.id}`}
                    index={item.index}
                  >
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Item
                          item={item}
                          onUpdate={(item) => this.onUpdate(item)}
                          onDelete={(item) => this.onDelete(item)}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="buttons">
          <Button onClick={() => this.onAdd()}>Add item</Button>
        </div>
      </div>
    );
  }
}
