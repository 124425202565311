import { Component } from "react";

import TextInput from "components/form/text-input";
import Icon from "components/icon";

import throttle from "lodash/throttle";

import API_ITEMS from "api/items";

import "./task-search.scss";

export default class extends Component {
  state = {
    searchValue: null,
    selectedTasks: [],
    searchItems: [],
  };

  constructor() {
    super(...arguments);

    this.getSearchItems = throttle(() => this._getSearchItems(), 500);
  }

  async _getSearchItems() {
    const { searchValue } = this.state;

    let searchItems = await API_ITEMS.search(searchValue);

    if (!searchItems) {
      searchItems = [];
    }

    this.setState({
      searchItems,
    });
  }

  onSearchInput(searchValue) {
    let { searchItems } = this.state;

    if (searchValue === "") {
      searchItems = [];
    }

    this.setState({
      searchValue,
      searchItems,
    });

    if (searchValue !== "") {
      this.getSearchItems();
    }
  }

  async onRemoveSelectedTask(_item) {
    const selectedTasks = this.state.selectedTasks.reduce(
      (agg, item) => (item.id === _item.id ? agg : [...agg, item]),
      []
    );

    await this.setState({
      selectedTasks,
    });

    return this.onUpdate();
  }

  async onAddToSelectedTasks(item) {
    const { selectedTasks } = this.state;

    const found = selectedTasks.find((_item) => _item.id === item.id) ?? false;
    if (found) {
      return;
    }

    await this.setState({
      selectedTasks: [...this.state.selectedTasks, item],
    });

    return this.onUpdate();
  }

  onUpdate() {
    const { selectedTasks } = this.state;
    return this.props.onUpdate?.(selectedTasks);
  }

  componentDidUpdate(prevProps) {
    if (
      this.selectedTasks.length !== prevProps.value.length &&
      this.selectedTasks.length === 0
    ) {
      this.setState({
        selectedTasks: this.props.value,
        searchValue: null,
        searchItems: [],
      });
    }
  }

  get selectedTasks() {
    return this.props.value ?? [];
  }

  get searchItems() {
    return this.state.searchItems.reduce(
      (agg, item) =>
        this.selectedTasks.find((_item) => item.id === _item.id)
          ? agg
          : [...agg, item],
      []
    );
  }

  render() {
    const { searchValue } = this.state;
    const { searchItems, selectedTasks } = this;

    return (
      <div className="task-search">
        <div className="fake-input">
          <TextInput
            placeholder="Search for task..."
            onUpdate={(value) => this.onSearchInput(value)}
            value={searchValue}
          />
          {selectedTasks.length > 0 ? (
            <div className="selected-tasks">
              <ul>
                {selectedTasks.map((item) => (
                  <li key={`selected-item-${item.id}`}>
                    <div className="item">
                      NA-{item.id}
                      <Icon onClick={() => this.onRemoveSelectedTask(item)}>
                        close
                      </Icon>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        {searchItems.length > 0 ? (
          <div className="found-tasks">
            <ul>
              {searchItems.map((item) => (
                <li
                  key={`search-item-${item.id}`}
                  onClick={() => this.onAddToSelectedTasks(item)}
                >
                  <div className="item">
                    <div className="name">
                      <strong>NA-{item.id}</strong> {item.name}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}
