import { Component } from "react";
import Accordion from "components/accordion";

export default class extends Component {
  get classNames() {
    const classes = ["board"];

    return classes.join(" ");
  }

  render() {
    const { board } = this.props;

    return (
      <div className={this.classNames}>
        <Accordion title={board.name}>{this.props.children}</Accordion>
      </div>
    );
  }
}
