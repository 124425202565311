import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { unassignLoggedInUser } from "store/user/actions";
import { getBoards } from "store/boards/actions";

import App from "./app.js";

function Wrapper(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <App {...props} params={params} navigate={navigate} location={location} />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    currentBoard: state.views.board.board,
    boards: state.boards,
  };
}

const mapDispatchToProps = {
  unassignLoggedInUser,
  getBoards,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
