import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { updateUser } from "store/user/actions";

import Accounts from "./accounts.js";

function Wrapper(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Accounts
      {...props}
      params={params}
      navigate={navigate}
      location={location}
    />
  );
}

function mapStateToProps(state) {
  return {
    users: state.users,
  };
}

const mapDispatchToProps = {
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
