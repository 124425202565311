import { LOAD_CHECKLISTS, GET_CHECKLISTS } from "./actions";
import { getById } from "utils/array";

const initialData = {
  byId: {},
  all: [],
  isLoading: true,
};

export function checklists(state = initialData, action) {
  switch (action.type) {
    case GET_CHECKLISTS:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_CHECKLISTS: {
      return {
        ...state,
        byId: getById(action.items),
        all: action.items,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

export default checklists;
