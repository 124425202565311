import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { loadView, getItem, updateItem } from "store/views/item/actions";

import {
  addComment,
  updateComment,
  deleteComment,
} from "store/views/item/comments/actions";

import { getUsers } from "store/users/actions";

import {
  addChecklist,
  deleteChecklist,
  updateChecklists,
} from "store/views/item/checklists/actions";

import { addLink, deleteLink } from "store/views/item/items/actions";

import Item from "./item.js";

function Wrapper(props) {
  const params = useParams();
  const navigate = useNavigate();

  return <Item {...props} params={params} navigate={navigate} />;
}

function mapStateToProps(state) {
  return {
    boards: state.boards,
    item: state.views.item.item,
    items: state.views.item.items,
    lists: state.views.item.lists,
    comments: state.views.item.comments,
    history: state.views.item.history,
    checklists: state.views.item.checklists,
    users: state.users,
    currentUser: state.user,
    isLoading:
      state.boards.isLoading ||
      state.views.item.item.isLoading ||
      state.views.item.lists.isLoading ||
      // state.views.item.comments.isLoading ||
      // state.views.item.history.isLoading ||
      !state.users.isLoaded ||
      state.user.isLoading,
  };
}

const mapDispatchToProps = {
  loadView,
  getItem,
  updateItem,
  getUsers,
  addComment,
  updateComment,
  deleteComment,
  addChecklist,
  deleteChecklist,
  updateChecklists,
  addLink,
  deleteLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
