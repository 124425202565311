import { Component } from "react";
import { render } from "react-dom";

import Profile from "components/user/profile/profile";

let resolve;

class ProfileDialog extends Component {
  constructor() {
    super(...arguments);

    this.state = {
      isOpen: false,
      props: {},
    };

    this.show = this.show.bind(this);
  }

  static create(props = {}) {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return render(<ProfileDialog props={props} />, containerElement);
  }

  onCancel() {
    this.setState({ isOpen: false });
    resolve(this.state.props.user);
  }

  onUpdate(user) {
    this.setState({
      ...this.state,
      props: {
        ...this.state.props,
        user: {
          ...this.state.props.user,
          ...user,
        },
      },
    });
  }

  show(props = {}) {
    console.log("show", props);

    this.setState({ isOpen: true, props });
    return new Promise((res) => {
      resolve = res;
    });
  }

  render() {
    const { isOpen, props } = this.state;

    return isOpen ? (
      <Profile
        user={props.user}
        updateUser={(user) => this.onUpdate(user)}
        onClose={() => this.onCancel()}
      />
    ) : null;
  }
}

export default ProfileDialog;
