import { LOAD_USERS, GET_USERS, RELOAD_USERS } from "./actions";

import { getById } from "utils/array";

const initialData = {
  byId: {},
  all: [],
  isLoading: true,
  isLoaded: false,
  lastTimeFetched: null,
};

export function users(state = initialData, action) {
  switch (action.type) {
    case GET_USERS:
    case RELOAD_USERS:
      return {
        ...state,
        isLoading: true,
      };

    case LOAD_USERS:
      const users = action.users;
      return {
        byId: getById(users),
        all: users,
        isLoading: false,
        isLoaded: true,
      };
    default:
      return state;
  }
}

export default users;
