import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

const update = (lists) =>
  axios
    .post(`${API_URL}/lists.update`, { lists }, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const get = () =>
  axios
    .get(`${API_URL}/lists.get/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

const getById = (listId) =>
  axios
    .get(`${API_URL}/lists.get/${listId}/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export default { update, get, getById };
