import { combineReducers } from "redux";

import user from "./user/reducers";
import users from "./users/reducers";
import boards from "./boards/reducers";
import views from "./views/reducers";

export default combineReducers({
  user,
  users,
  boards,
  views,
});
