import API_BOARDS from "api/boards";

import { clearLists, getLists } from "store/views/board/lists/actions";
import {
  clearItems,
  getItems,
  updateItems,
} from "store/views/board/items/actions";
import { getUsers } from "store/users/actions";

export const LOAD_BOARD = "VIEW_BOARD_LOAD_BOARD";

export const LOAD_BOARD_VIEW = "VIEW_BOARD_LOAD_BOARD_VIEW";
export const LOAD_BOARD_VIEW_SUCCESS = "VIEW_BOARD_LOAD_BOARD_VIEW_SUCCESS";
export const LOAD_BOARD_VIEW_FAILED = "VIEW_BOARD_LOAD_BOARD_VIEW_FAILED";

export const GET_BOARD = "VIEW_BOARD_GET_BOARD";
export const GET_BOARD_SUCCESS = "VIEW_BOARD_GET_BOARD_SUCCESS";
export const GET_BOARD_FAILED = "VIEW_BOARD_GET_BOARD_FAILED";

export const UPDATE_BOARD = "VIEW_BOARD_UPDATE_BOARD";
export const UPDATE_BOARD_SUCCESS = "VIEW_BOARD_UPDATE_BOARD_SUCCESS";
export const UPDATE_BOARD_FAILED = "VIEW_BOARD_UPDATE_BOARD_FAILED";

export const loadBoard = (board) => async (dispatch, getState) => {
  return dispatch({
    type: LOAD_BOARD,
    board,
  });
};

export const loadView = (boardId) => async (dispatch, getState) => {
  await dispatch({
    type: LOAD_BOARD_VIEW,
    boardId,
  });

  await dispatch(getLists());

  const { boards } = getState();
  const board = boards.byId[boardId];

  if (!board) {
    dispatch(clearLists());
    dispatch(clearItems());

    return dispatch({
      type: LOAD_BOARD_VIEW_FAILED,
    });
  }

  await dispatch(loadBoard(board));

  await dispatch(getItems());
  await dispatch(getUsers());

  dispatch({
    type: LOAD_BOARD_VIEW_SUCCESS,
  });
};

export const updateBoard = (board) => async (dispatch, getState) => {
  await dispatch({
    type: UPDATE_BOARD,
    board,
  });

  const result = await API_BOARDS.update(board);

  if (!result) {
    return dispatch({
      type: UPDATE_BOARD_FAILED,
    });
  }

  dispatch({
    type: UPDATE_BOARD_SUCCESS,
  });
};

export const updateView =
  ({ items }) =>
  async (dispatch) => {
    await dispatch(updateItems(items));
  };
