import { getBoards } from "./boards/actions";

export const LOAD = "VIEW_BOARDS_LOAD";
export const LOAD_SUCCESS = "VIEW_BOARDS_LOAD_SUCCESS";
export const LOAD_FAILED = "VIEW_BOARDS_LOAD_FAILED";
export const LOADED = "VIEW_BOARDS_LOADED";

export const loadItem = (item) => {
  return {
    type: LOADED,
    item,
  };
};

export const loadView = () => async (dispatch) => {
  await dispatch({
    type: LOAD,
  });

  await dispatch(getBoards());

  await dispatch({
    type: LOAD_SUCCESS,
  });

  dispatch(loadItem());
};
