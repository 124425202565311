import { Component } from "react";

import ButtonIcon from "components/button-icon";
import Button from "components/button";
import Checkbox from "components/form/checkbox";

import ConfirmService from "services/dialog/confirm";
import ProfileService from "services/dialog/profile";

import "./accounts.scss";

export default class extends Component {
  async onDelete(user) {
    const confirm = await ConfirmService.confirm(
      "Are you sure you want to delete this user? This action cannot be undone."
    );

    if (confirm) {
      return this.props.onDelete(user);
    }

    return false;
  }

  async onEdit(user) {
    const updated = await ProfileService.show({ user });

    return this.props.updateUser({ ...user, ...updated });
  }

  onAdd() {
    console.log("onAdd");
  }

  render() {
    const users = this.props.users.all;

    return (
      <div className="accounts">
        <h1>Accounts</h1>
        <table cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email address</th>
              <th>Admin</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={`user-${user.id}`}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  <Checkbox checked={user.isAdmin} onClick={() => {}} />
                </td>
                <td className="actions">
                  <ButtonIcon onClick={() => this.onEdit(user)}>
                    edit
                  </ButtonIcon>
                  <ButtonIcon onClick={() => this.onDelete(user)}>
                    delete
                  </ButtonIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="footer">
          <Button onClick={() => this.onAdd()}>Add Account</Button>
        </div>
      </div>
    );
  }
}
