import { Component } from "react";
import { Droppable } from "react-beautiful-dnd";

import Card from "./card";

import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    items: state.views.board.items,
  };
}

const mapDispatchToProps = {};

class List extends Component {
  get list() {
    return this.props.model;
  }

  get listId() {
    return this.list.id;
  }

  get items() {
    return this.props.items.all.reduce(
      (agg, item) => (item.listId === this.listId ? [...agg, item] : agg),
      []
    );
  }

  render() {
    const { list } = this;

    return (
      <div className="list-holder">
        <div className="wrapper">
          <div className="title">
            {list.name} <span className="count">{this.items.length}</span>
          </div>
          <div className="body">
            <Droppable droppableId={`droppable-${this.listId}`}>
              {(provided, snapshot) => (
                <div
                  className="list"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {this.items.map((item, index) => (
                    <Card
                      model={item}
                      key={item.id}
                      index={index}
                      onViewItem={this.props.onViewItem}
                    />
                  ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
