import { Component } from "react";
import { Droppable } from "react-beautiful-dnd";

import Item from "./item";

export default class List extends Component {
  render() {
    const { children, "list-id": listId } = this.props;

    return (
      <Droppable droppableId={`droppable-${listId}`}>
        {(provided) => (
          <div
            className="list"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children?.map((item, index) => {
              const itemId = item.props["item-id"];
              return (
                <Item id={itemId} key={itemId} index={index}>
                  {item}
                </Item>
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}
