import { LOAD_LIST, GET_LIST, RELOAD_LIST } from "./actions";

const initialData = {
  id: null,
  name: null,
  isLoading: true,
};

export function item(state = initialData, action) {
  switch (action.type) {
    case GET_LIST:
    case RELOAD_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_LIST:
      return {
        ...action.item,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default item;
