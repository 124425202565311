import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { loadView, updateBoard } from "store/views/board/actions";
import { updateItems } from "store/views/board/items/actions";
import { getBoards } from "store/boards/actions";

// import { loadView, updateView } from "store/views/board/actions";

import Archive from "./archive";

function Wrapper(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Archive
      {...props}
      params={params}
      navigate={navigate}
      location={location}
    />
  );
}

function mapStateToProps(state) {
  return {
    ...state.views.board,
    boards: state.boards,
  };
}

const mapDispatchToProps = { loadView, getBoards, updateItems, updateBoard };

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
