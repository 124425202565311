import { Component } from "react";
import ContentEditable from "components/form/content-editable";

export default class Name extends Component {
  render() {
    return (
      <div className="name">
        <ContentEditable
          html={this.props.name}
          onChange={(v) => this.props.onUpdate(v)}
        />
      </div>
    );
  }
}
