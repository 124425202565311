import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

export const add = (type, associatedId, comment) =>
  axios
    .post(
      `${API_URL}/comments.post/${type}/${associatedId}`,
      { comment },
      getAxiosOptions()
    )
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export const commentOnItem = (itemId, comment) => add("items", itemId, comment);

export const get = (type, associatedId) =>
  axios
    .get(`${API_URL}/comments.get/${type}/${associatedId}`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export const update = (comment) =>
  axios
    .post(`${API_URL}/comments.update/`, { comment }, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export const remove = (comment) =>
  axios
    .delete(`${API_URL}/comments.remove/${comment.id}/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export default { commentOnItem, add, get, update, remove };
