import { login, authenticate } from "api/auth";
import API_USER from "api/user";

import { reloadUsers } from "store/users/actions";

export const ASSIGN_LOGGED_IN_USER = "ASSIGN_LOGGED_IN_USER";
export const UNASSIGN_LOGGED_IN_USER = "UNASSIGN_LOGGED_IN_USER";
export const ATTEMPT_TO_LOGIN = "ATTEMPT_TO_LOGIN";

export const ATTEMPT_TO_LOGIN_FAILED = "ATTEMPT_TO_LOGIN_FAILED";
export const ATTEMPT_TO_LOGIN_SUCCESS = "ATTEMPT_TO_LOGIN_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const GET_USER = "GET_USER";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const LOAD_USER = "LOAD_USER";

export const loadUser = (user) => {
  return {
    type: LOAD_USER,
    user,
  };
};

export const assignLoggedInUser = (user) => {
  return {
    type: ASSIGN_LOGGED_IN_USER,
    user,
  };
};

export const unassignLoggedInUser = () => {
  return {
    type: UNASSIGN_LOGGED_IN_USER,
  };
};

export const attemptToLogin = (email, password) => async (dispatch) => {
  await dispatch({
    type: ATTEMPT_TO_LOGIN,
  });

  const user = await login(email, password);

  if (!user) {
    return dispatch({
      type: ATTEMPT_TO_LOGIN_FAILED,
    });
  }

  await dispatch({
    type: ATTEMPT_TO_LOGIN_SUCCESS,
  });

  await dispatch(assignLoggedInUser(user));
};

export const updateUser = (user) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER,
  });

  const result = await API_USER.update(user);

  if (!result) {
    return dispatch({
      type: UPDATE_USER_FAILED,
    });
  }

  dispatch({
    type: UPDATE_USER_SUCCESS,
  });

  dispatch(getUser());
};

export const getUser = () => async (dispatch) => {
  dispatch({
    type: GET_USER,
  });

  const user = await authenticate();

  if (!user) {
    return dispatch({
      type: GET_USER_FAILED,
    });
  }

  dispatch({
    type: GET_USER_SUCCESS,
  });

  dispatch(loadUser(user));
  dispatch(reloadUsers());
};
