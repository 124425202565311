import API_CHECKLISTS from "api/checklists";

export const LOAD_CHECKLISTS = "VIEW_ITEM_LOAD_CHECKLISTS";

export const GET_CHECKLISTS = "VIEW_ITEM_GET_CHECKLISTS";
export const GET_CHECKLISTS_SUCCESS = "VIEW_ITEM_GET_CHECKLISTS_SUCCESS";
export const GET_CHECKLISTS_FAILED = "VIEW_ITEM_GET_CHECKLISTS_FAILED";

const getItemId = (getState) => getState().views.item.item.id;

export const loadChecklists = (items) => {
  return {
    type: LOAD_CHECKLISTS,
    items,
  };
};

export const getChecklists = () => async (dispatch, getState) => {
  const itemId = getItemId(getState);

  dispatch({
    type: GET_CHECKLISTS,
    itemId,
  });

  const items = await API_CHECKLISTS.getItemChecklists(itemId);

  if (!items) {
    return dispatch({
      type: GET_CHECKLISTS_FAILED,
    });
  }

  dispatch({
    type: GET_CHECKLISTS_SUCCESS,
  });

  dispatch(loadChecklists(items));
};

export const updateChecklists = (items) => async (dispatch, getState) => {
  await API_CHECKLISTS.update(items);
  await dispatch(getChecklists());
};
export const deleteChecklist = (item) => async (dispatch, getState) => {
  await API_CHECKLISTS.remove(item);
  await dispatch(getChecklists());
};
export const addChecklist = (item) => async (dispatch, getState) => {
  const itemId = getItemId(getState);
  await API_CHECKLISTS.checklistOnItem(itemId, item);
  await dispatch(getChecklists());
};
