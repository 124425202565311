import { GET_ITEMS, LOAD_ITEMS } from "./actions";
import { getById } from "utils/array";

const initialData = {
  blocked: [],
  blocking: [],
  linked: [],
  isLoading: true,
};

export function items(state = initialData, action) {
  switch (action.type) {
    case GET_ITEMS:
      return { ...state, isLoading: true };
    case LOAD_ITEMS: {
      return action.items.reduce((agg, item) => {
        switch (item.linkType) {
          case "blocked":
            return {
              ...agg,
              blocked: [...agg.blocked, item],
            };
          case "blocking":
            return {
              ...agg,
              blocking: [...agg.blocking, item],
            };
          case "linking":
          case "linked":
            return {
              ...agg,
              linked: [...agg.linked, item],
            };
        }

        return agg;
      }, initialData);
    }
    default:
      return state;
  }
}

export default items;
