import { Component } from "react";
import "./tabs.scss";

export default class extends Component {
  state = { selectedId: this.props.selectedId };

  get classNames() {
    const classes = ["tabs"];

    return classes.join(" ");
  }

  onClick(itemId) {
    this.setState({
      selectedId: itemId,
    });
  }

  render() {
    const { items } = this.props;
    const { selectedId } = this.state;

    return (
      <div className={this.classNames}>
        <div className="navigation">
          {Object.values(items).map((item) => (
            <div
              className={
                selectedId === item.id
                  ? "navigation-tab is-active"
                  : "navigation-tab"
              }
              onClick={() => this.onClick(item.id)}
              key={`${item.id}-${item.name}`}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="body">{items[selectedId]?.element}</div>
      </div>
    );
  }
}
