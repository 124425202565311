import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

const upload = async (file) => {
  const formData = new FormData();
  formData.append(`file`, file);

  return axios
    .post(`${API_URL}/assets.upload/`, formData, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);
};

const get = (assetId) =>
  axios
    .get(`${API_URL}/assets.get/${assetId}/`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export default { upload, get };
