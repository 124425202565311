import { LOADED, LOAD } from "./actions";

import { combineReducers } from "redux";

import boards from "./boards/reducers";

const initialData = {
  isLoading: true,
};

export function view(state = initialData, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default combineReducers({ view, boards });
