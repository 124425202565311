import API_BOARDS from "api/boards";

export const LOAD_BOARDS = "LOAD_BOARDS";
export const GET_BOARDS = "GET_BOARDS";
export const GET_BOARDS_FAILED = "GET_BOARDS_FAILED";
export const GET_BOARDS_SUCCESS = "GET_BOARDS_SUCCESS";

export const loadBoards = (boards) => {
  return {
    type: LOAD_BOARDS,
    boards,
  };
};

export const getBoards = () => async (dispatch) => {
  dispatch({
    type: GET_BOARDS,
  });

  const boards = await API_BOARDS.get();

  if (!boards) {
    return dispatch({
      type: GET_BOARDS_FAILED,
    });
  }

  dispatch({
    type: GET_BOARDS_SUCCESS,
  });

  dispatch(loadBoards(boards));
};
