import { GET_BOARDS, LOAD_BOARDS } from "./actions";
import { getById } from "utils/array";

const initialData = {
  byId: {},
  all: [],
  isLoading: true,
};

export function boards(state = initialData, action) {
  switch (action.type) {
    case GET_BOARDS:
      return { ...state, isLoading: true };
    case LOAD_BOARDS:
      const boards = action.boards;

      return {
        ...state,
        byId: getById(boards),
        all: boards,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default boards;
