import { Component } from "react";
import { Draggable } from "react-beautiful-dnd";

import Picture from "components/user/picture";

export default class Card extends Component {
  render() {
    const model = this.props.model ?? {};

    return (
      <Draggable draggableId={`draggable-${model.id}`} index={model.index}>
        {(provided, snapshot) => (
          <div
            className="card"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => this.props.onViewItem(model)}
          >
            <span className="name">{model.name}</span>
            <div className="bottom">
              <div className="id">NA-{model.id}</div>
              <div className={`priority priority-${model.priority}`}>
                <span className="material-icons">priority_high</span>
                <span className="material-icons">priority_high</span>
                <span className="material-icons">priority_high</span>
              </div>
              <div className="assignee">
                {model.assigneeId ? (
                  <Picture userId={model.assigneeId} />
                ) : null}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}
