import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

const update = (user) =>
  axios
    .post(`${API_URL}/user.update/`, { user }, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return true;
      }

      return false;
    })
    .catch(onError);

export default { update };
