import { connect } from "react-redux";
import Login from "./login";

import { attemptToLogin } from "store/user/actions";

function mapStateToProps(state) {
  return { user: state.user };
}

const mapDispatchToProps = {
  attemptToLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
