import React, { Component } from "react";
import escapeHtml from "escape-html";
import { Text } from "slate";
import { API_URL } from "config/axios";

const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }
    return string;
  }

  let children = node.children.map((n) => serialize(n)).join("");

  if (children === "") {
    children = "&nbsp;";
  }

  switch (node.type) {
    case "image":
      return `<img src="${API_URL}/assets.get/${node.url}" />`;
    case "block-quote":
      return `<blockquote>${children}</blockquote>`;
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "heading-one":
      return `<h1>${children}</h1>`;
    case "heading-two":
      return `<h2>${children}</h2>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "numbered-list":
      return `<ol>${children}</ol>`;
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

function Paragraph(props) {
  return <p>{props.children}</p>;
}

function Image(props) {
  return <img src={props.src} />;
}

export default class TextEditorOutput extends Component {
  get uniqueId() {
    return this.props.uniqueId ?? null;
  }

  get data() {
    return this.props.data;
  }

  get output() {
    try {
      const json = JSON.parse(this.data);

      if (!(json?.length > 0)) {
        return null;
      }

      return serialize({ children: json });
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <div
        className="text-editor-output"
        dangerouslySetInnerHTML={{ __html: this.output }}
      />
    );
  }
}
