export const resetIndexes = (items, excludedId = null) =>
  items.reduce(
    (agg, item, index) =>
      excludedId !== item.id && item.index === index
        ? agg
        : [
            ...agg,
            {
              ...item,
              index,
            },
          ],
    []
  );

export const reorderItem = (list, startIndex, endIndex) => {
  const [item] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, item);
  return [...resetIndexes(list)];
};

export const moveItem = (source, destination) => {
  let [item] = source.items.splice(source.index, 1);

  if (!item) {
    [item, source.items] = source.items.reduce(
      (agg, item) => {
        if (item.id === source.itemId) {
          return [item, agg[1]];
        }

        return [agg[0], [...agg[1], item]];
      },
      [null, []]
    );

    if (!item) {
      return false;
    }
  }

  destination.items.splice(destination.index, 0, {
    ...item,
    listId: destination.listId,
  });

  return [
    ...resetIndexes(source.items),
    ...resetIndexes(destination.items, item.id),
  ];
};
