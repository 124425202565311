import { Component } from "react";
import { v4 as uuidv4 } from "uuid";

import "./text-input.scss";

export default class extends Component {
  get classNames() {
    const classes = ["text-input"];

    return classes.join(" ");
  }

  get name() {
    return this.props.name ?? uuidv4();
  }

  get value() {
    return this.props.value;
  }

  get label() {
    return this.props.label ?? null;
  }

  get type() {
    return this.props.type ?? "text";
  }

  get autocomplete() {
    return this.props.autocomplete;
  }

  get placeholder() {
    return this.props.placeholder;
  }

  onUpdate(e) {
    const value = e.target.value;

    return (
      this.props.onUpdate?.(value) ||
      console.warn("No onUpdate specified for select component", {
        ...this,
        value,
      })
    );
  }

  componentDidUpdate() {
    if (this.value === "" || this.value === null) {
      this.element.value = "";
    }
  }

  render() {
    return (
      <div className={this.classNames}>
        <label>{this.label}</label>
        <div className="input-wrapper">
          <input
            ref={(el) => (this.element = el)}
            onChange={(e) => this.onUpdate(e)}
            defaultValue={this.value}
            name={this.name}
            type={this.type}
            autoComplete={this.autocomplete}
            placeholder={this.placeholder}
            readOnly
            onFocus={function (e) {
              console.log("this");
              e.target.removeAttribute("readonly");
            }}
            // onfocus="this.removeAttribute('readonly');"
          />
        </div>
      </div>
    );
  }
}
