import { Component } from "react";
import "./checkbox.scss";

import Icon from "components/icon";

export default class extends Component {
  get classNames() {
    const classes = ["input checkbox"];

    if (this.isChecked) {
      classes.push("is-checked");
    }

    return classes.join(" ");
  }

  get isChecked() {
    return this.props.checked ?? false;
  }

  render() {
    return (
      <div className={this.classNames} onClick={() => this.props.onClick()}>
        {this.isChecked ? <Icon>checked</Icon> : <Icon>close</Icon>}
      </div>
    );
  }
}
