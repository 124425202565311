import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { loadView } from "store/views/boards/actions";

import Items from "./items.js";

function Wrapper(props) {
  const params = useParams();
  const navigate = useNavigate();

  return <Items {...props} params={params} navigate={navigate} />;
}

function mapStateToProps(state) {
  return {
    boards: state.views.boards.boards,
  };
}

const mapDispatchToProps = { loadView };

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
