import { GET_ITEMS, LOAD_ITEMS, UPDATE_ITEMS, CLEAR_ITEMS } from "./actions";
import { getById } from "utils/array";

const initialData = {
  byId: {},
  all: [],
  isLoading: true,
};

export function items(state = initialData, action) {
  switch (action.type) {
    case CLEAR_ITEMS: {
      return { ...initialData };
    }
    case GET_ITEMS:
      return { ...state, isLoading: true };
    case UPDATE_ITEMS: {
      const items = [...state.all]
        .map((item) =>
          action.items.reduce(
            (agg, _item) =>
              _item.id === item.id ? { ...item, ..._item } : agg,
            item
          )
        )
        .sort((a, b) => a.index - b.index);
      return {
        ...state,
        byId: getById(items),
        all: items,
        isLoading: false,
      };
    }
    case LOAD_ITEMS: {
      const items = action.items.sort((a, b) => a.index - b.index);
      return {
        ...state,
        byId: getById(items),
        all: items,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

export default items;
