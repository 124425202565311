import { Component } from "react";

import Dialog from "components/dialog";
import Button from "components/button";
import Select from "components/form/select-input";

import "./archive.scss";
import { item } from "store/views/item/reducers";

export default class extends Component {
  state = {
    currentAction: 1,
    moveToBoardId: 0,
  };

  get board() {
    return this.props.board;
  }

  get lists() {
    return this.props.lists;
  }

  get items() {
    return this.props.items;
  }

  get boards() {
    return this.props.boards;
  }

  get itemsRemaining() {
    return this.items.all.reduce((agg, item) => {
      const list = this.lists.byId[item.listId];
      return list.isDone ? agg : [...agg, item];
    }, []);
  }

  get hasItemsRemaining() {
    return this.itemsRemaining.length > 0;
  }

  onActionChange(value) {
    this.setState({
      currentAction: parseInt(value),
    });
  }

  onSubactionChange(column, value) {
    this.setState({
      [column]: value,
    });
  }

  get availableBoards() {
    return [
      { id: 0, name: "Backlog" },
      ...this.boards.all.reduce(
        (agg, board) => (board.id === this.board.id ? agg : [...agg, board]),
        []
      ),
    ];
  }

  async onArchive() {
    const { hasItemsRemaining } = this;
    const { currentAction } = this.state;

    if (hasItemsRemaining) {
      switch (currentAction) {
        case 1: {
          const { moveToBoardId } = this.state;
          const { itemsRemaining } = this;

          const items = itemsRemaining.map((item) => ({
            ...item,
            boardId: moveToBoardId,
          }));

          this.props.updateItems(items);

          // where?
          break;
        }
        case 2: {
          console.log("Archive items");
          // Do nothing with the items ez
          break;
        }
      }
    }

    await this.props.updateBoard([{ ...this.board, isActive: false }]);
    await this.props.getBoards();
    await this.props.loadView(this.board.id);

    return this.props.onClose?.();
  }

  render() {
    const { props, hasItemsRemaining } = this;
    const { currentAction, moveToBoardId } = this.state;

    return (
      <Dialog
        title="Archive Board"
        size="small"
        onClose={() => props.onClose?.()}
      >
        <div className="archive-board-action">
          <p>Please click to confirm archiving of this board</p>
          {hasItemsRemaining ? (
            <div className="pending-items">
              <span>Unfinished tasks</span>
              <label>
                <input
                  type="radio"
                  name="action"
                  value="1"
                  checked={currentAction === 1}
                  onChange={(e) => this.onActionChange(e.target.value)}
                />
                <span>Move unfinished items</span>
                {currentAction === 1 ? (
                  <Select
                    name="board"
                    items={this.availableBoards}
                    value={moveToBoardId}
                    onUpdate={(value) =>
                      this.onSubactionChange("moveToBoardId", value)
                    }
                  />
                ) : null}
              </label>
              <label>
                <input
                  type="radio"
                  name="action"
                  value="2"
                  checked={currentAction === 2}
                  onChange={(e) => this.onActionChange(e.target.value)}
                />
                <span>Archive all items</span>
              </label>
            </div>
          ) : null}

          <div className="buttons">
            <Button onClick={() => this.onArchive()}>Archive</Button>
            <Button onClick={() => props.onClose?.()}>Cancel</Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
