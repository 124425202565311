import { Component } from "react";
import Dialog from "components/dialog";
import UploadPicture from "./upload-picture";

import "./profile.scss";

import Fieldset from "components/form/fieldset";
import TextInput from "components/form/text-input";

import { v4 as uuid } from "uuid";

const API_URL = `//${process.env.REACT_APP_SERVER_DOMAIN}`;

export default class extends Component {
  state = {
    password: {
      password: null,
      password_confirm: null,
    },
    user: { id: this.props.user.id },
  };

  onClose() {}

  async onUpdate(column, value) {
    if (column === "password" || column === "password_confirm") {
      await this.setState({
        password: {
          ...this.state.password,
          [column]: value,
        },
      });

      if (this.hasPasswordBeenUpdated && this.doPasswordsMatch) {
        const { password } = this.state.password;

        await this.setState({
          user: {
            ...this.state.user,
            password,
          },
        });

        this.props.updateUser({ ...this.state.user });
      }
    } else {
      await this.setState({
        user: {
          ...this.state.user,
          [column]: value,
        },
      });

      this.props.updateUser({ ...this.state.user });
    }
  }

  get hasPasswordBeenUpdated() {
    const { password } = this.state.password;
    return !(password === null || password === "");
  }

  get doPasswordsMatch() {
    const { password, password_confirm } = this.state.password;
    return password === password_confirm;
  }

  render() {
    const { user } = this.props;

    return (
      <Dialog
        title={`Edit Profile`}
        onClose={() => this.props.onClose()}
        size="small"
      >
        <div className="view-profile">
          <Fieldset
            title="Your details"
            text="Change your name and email address"
          >
            <TextInput
              label="Full name"
              name="name"
              value={user.name}
              onUpdate={(value) => this.onUpdate("name", value)}
            />
            <TextInput
              label="Email address"
              name="email"
              type="email"
              value={user.email}
              onUpdate={(value) => this.onUpdate("email", value)}
            />
          </Fieldset>

          <Fieldset
            title="Change your password"
            text="Leave blank to keep existing password"
          >
            <TextInput
              label="Password"
              name="password"
              type="password"
              autocomplete={uuid()}
              onUpdate={(value) => this.onUpdate("password", value)}
            />
            {this.hasPasswordBeenUpdated ? (
              <>
                <TextInput
                  label="Confirm your new password"
                  name="password_confirm"
                  type="password"
                  autocomplete={uuid()}
                  onUpdate={(value) => this.onUpdate("password_confirm", value)}
                />
                {this.doPasswordsMatch ? (
                  <p className="message good">Passwords are matching</p>
                ) : (
                  <p className="message bad">Passwords are not matching</p>
                )}
              </>
            ) : null}
          </Fieldset>

          <Fieldset
            title="Change your profile picture"
            text="Upload a unique image to clearly identify yourself across the board"
          >
            <UploadPicture
              onUpdate={(fileKey) => this.onUpdate("picture", fileKey)}
              image={`${API_URL}/assets.get/${user.picture}`}
            />
          </Fieldset>
        </div>
      </Dialog>
    );
  }
}
