import { Component } from "react";
import { Outlet } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";

import List from "./list";

import { reorderItem, moveItem } from "./utils";

import "./styles/board.scss";

export default class Board extends Component {
  constructor() {
    super(...arguments);

    this.props.loadView(this.boardId);
  }

  get boardId() {
    return this.props.params.boardId;
  }

  get board() {
    return this.props.board;
  }

  get items() {
    return this.props.items;
  }

  get lists() {
    return this.props.lists;
  }

  componentDidUpdate(prevProps, prevState) {
    const prevRoute = prevProps.location.pathname;
    const currentRoute = this.props.location.pathname;

    const isGoingForward = currentRoute.length > prevRoute.length;
    if (prevRoute !== currentRoute && !isGoingForward) {
      this.props.loadView(this.boardId);
    }
  }

  getItemsByListId(listId) {
    return this.props.items.all.reduce(
      (agg, item) => (item.listId === listId ? [...agg, item] : agg),
      []
    );
  }

  async onItemMove(result) {
    const itemId = parseInt(result.draggableId.replace("draggable-", ""));
    const item = this.items.byId[itemId];

    if (!item || !result?.destination?.droppableId) {
      return console.warn("No item was found matching the id");
    }

    const source = {
      itemId,
      listId: parseInt(result.source.droppableId.replace("droppable-", "")),
      index: parseInt(result.source.index),
    };

    const destination = {
      listId: parseInt(
        result.destination.droppableId.replace("droppable-", "")
      ),
      index: parseInt(result.destination.index),
    };

    source["items"] = [...this.getItemsByListId(source.listId)];
    destination["items"] = [...this.getItemsByListId(destination.listId)];

    const isMovingList = source.listId !== destination.listId;

    let items = [];
    if (isMovingList) {
      items = moveItem(source, destination);
    } else {
      items = reorderItem(source.items, source.index, destination.index);
    }

    if (!items) {
      return console.warn("Illegal move buddy", { source, destination });
    }

    await this.props.updateView({ items });

    return true;
  }

  render() {
    const lists = this.lists.all ?? [];

    return (
      <div className="board">
        <DragDropContext onDragEnd={(model) => this.onItemMove(model)}>
          <div className="lists">
            {lists.map((list) => (
              <List
                model={list}
                key={`list-holder-${list.id}`}
                onViewItem={(item) => this.props.navigate(`item/${item.id}`)}
              />
            ))}
          </div>
        </DragDropContext>
        <Outlet />
      </div>
    );
  }
}
