import { Component } from "react";
import Select from "components/form/select-input";

export default class Board extends Component {
  render() {
    const model = this.props.model ?? [];
    const boardId = this.props.boardId ?? null;

    return (
      <Select
        label="Board"
        name="board"
        items={[{ id: 0, name: "Backlog" }, ...model]}
        value={boardId}
        onUpdate={(value) => this.props.onUpdate(value)}
      />
    );
  }
}
