import { Component } from "react";

import "./fieldset.scss";

export default class extends Component {
  get title() {
    return this.props.title ?? null;
  }

  get text() {
    return this.props.text ?? null;
  }

  render() {
    return (
      <div className="fieldset">
        {this.title ? <h1>{this.title}</h1> : null}
        {this.text ? <p>{this.text}</p> : null}
        <div className="body">{this.props.children}</div>
      </div>
    );
  }
}
