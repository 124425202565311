import { Component } from "react";
import { Draggable } from "react-beautiful-dnd";

export default class Item extends Component {
  render() {
    const { index, id } = this.props;

    return (
      <Draggable draggableId={`draggable-${id}`} index={index}>
        {(provided, snapshot) => (
          <div
            className="item-container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {this.props.children}
          </div>
        )}
      </Draggable>
    );
  }
}
