import { Component } from "react";
import { v4 as uuidv4 } from "uuid";

import "./select-input.scss";

export default class Select extends Component {
  get name() {
    return this.props.name ?? uuidv4();
  }

  get items() {
    return this.props.items ?? [];
  }

  get value() {
    return this.props.value ?? null;
  }

  get label() {
    return this.props.label ?? null;
  }

  onUpdate(e) {
    const value = parseInt(e.target.value);

    return (
      this.props.onUpdate?.(value) ||
      console.warn("No onUpdate specified for select component", {
        ...this,
        value,
      })
    );
  }

  render() {
    return (
      <div className="select-input">
        <label>{this.label}</label>
        <div className="input-wrapper">
          <select
            onChange={(e) => this.onUpdate(e)}
            defaultValue={this.value}
            name={this.name}
          >
            {this.items.map((item) => (
              <option value={item.id} key={`${this.name}-${item.id}`}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}
