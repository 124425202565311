import { Component } from "react";
import TextEditor from "components/form/text-editor";
import TextEditorOutput from "components/form/text-editor/output";

import Accordion from "components/accordion";
import Button from "components/button";

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "Enter some text here" }],
  },
];

export default class Description extends Component {
  state = {
    isEditing: false,
    value: null,
  };

  get description() {
    if (this.props.description) {
      return this.props.description;
    }

    return JSON.stringify(initialValue);
  }

  onEdit() {
    this.setState({
      isEditing: true,
    });
  }

  onChange(value) {
    this.setState({
      value,
    });
  }

  onCancel() {
    this.setState({
      isEditing: false,
      value: null,
    });
  }

  async onSave() {
    await this.props.onUpdate(this.state.value);
    this.setState({
      isEditing: false,
      value: null,
    });
  }

  render() {
    const { description } = this;

    return (
      <div className="description">
        {this.state.isEditing ? (
          <div className="wrapper">
            <TextEditor
              data={description}
              onChange={(value) => this.onChange(value)}
              stayFocused={true}
            />
            <div className="actions">
              <Button onClick={() => this.onSave()}>Save</Button>
              <Button onClick={() => this.onCancel()}>Cancel</Button>
            </div>
          </div>
        ) : (
          <div className="output" onClick={() => this.onEdit()}>
            <TextEditorOutput data={description} />
          </div>
        )}
      </div>
    );
  }
}
