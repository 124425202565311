import axios from "axios";
import { onError, getAxiosOptions, API_URL } from "config/axios";

const getItemHistory = (itemId) =>
  axios
    .get(`${API_URL}/history.get/items/${itemId}`, getAxiosOptions())
    .then(function ({ data }) {
      if (data.success) {
        return data.result;
      }

      return null;
    })
    .catch(onError);

export default { getItemHistory };
