import { Component } from "react";

import "./picture.scss";

const API_URL = `//${process.env.REACT_APP_SERVER_DOMAIN}`;

export default class extends Component {
  get initials() {
    const user = this.user;

    if (!user.name) {
      return;
    }

    const [first, last] = user.name.split(" ");
    return `${first[0]}${last[0]}`;
  }

  get userId() {
    return this.props.userId;
  }

  get user() {
    return this.props.users?.[this.userId] ?? {};
  }

  render() {
    const user = this.user;

    return (
      <div className="user-picture" onClick={() => this.props.onClick?.()}>
        {user.picture ? (
          <div className="picture">
            <img src={`${API_URL}/assets.get/${user.picture}`} />
          </div>
        ) : (
          <div className="initials">{this.initials}</div>
        )}
        {this.props.hasName ? <div className="name">{user.name}</div> : null}
      </div>
    );
  }
}
